import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
	public showRoutesNav: boolean;
	@Output() newItemEvent = new EventEmitter<boolean>();
	private _routeChangedSubscription: Subscription;
	constructor(private _router: Router) {
		this.showRoutesNav = true;
	}

	private onRouteChanged = (event: NavigationEnd) => {
		if (event instanceof NavigationEnd) {

			this.showRoutesNav = event.url.indexOf('/routes') >= 0;
		}
	}

	ngOnInit() {
		this._routeChangedSubscription = this._router.events.subscribe(this.onRouteChanged);

		let navbar = document.querySelector("#sidebar_bg");
		

	}

	ngOnDestroy() {
		this._routeChangedSubscription.unsubscribe();
	}
	addNewItem(value: boolean) {
		var url = window.location.href.split('routes');
		if (url.length > 0)
			value = true;
		else
			value = false;
		//alert(value);
		this.newItemEvent.emit(value);
	}
}
