import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { ICustomer, IError, WellService, APP_CONFIG, IAppConfig,
	IPage, IWellListItem, ICustomerRouteChange } from 'ee-common-lib';
export const DEFAULT_WELL_SEARCH_REPORT_NAME = 'Well Reports';
interface IRouteData {
	producers: ICustomer[];
}

@Component({
	selector: 'app-wells',
	templateUrl: './wells.component.html',
	styleUrls: ['./wells.component.scss']
})
export class WellsComponent implements OnInit, OnDestroy {
	private _routeDataSub: Subscription;
	private _refreshWellsSub: Subscription;

	public _producerID: number;
	public _routeID: number;
	private _searchKeyword: string;
	public producers: ICustomer[];
	public wells: IWellListItem[];

	public page: number;
	public pageSize: number;
	public pageCount: number;
	public totalWellCount: number;

	public loading: boolean;
	public errorMsg: string;
	public successMsg: string;
	public wellSearchExportFileName = DEFAULT_WELL_SEARCH_REPORT_NAME;
	constructor(
		private _route: ActivatedRoute,
		private _wellService: WellService,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
			this.page = 1;
			this.pageSize = this._appConfig.pageSize;
		}

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.producers = data.producers;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch well data: ${error.Message}`;
			}
		});

		this.refreshWells();
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
		this.cancelRefreshWells();
	}

	public onCustomerRouteChanged(customerRouteChange: ICustomerRouteChange) {
		this._producerID = customerRouteChange.CustomerID;
		this._routeID = customerRouteChange.RouteID;
		this._searchKeyword = customerRouteChange.SearchKeyword;
		this.page = 1;

		this.refreshWells();
	}

	public refreshWells(): void {
		this.cancelRefreshWells();

		let request: Observable<IPage<IWellListItem>>;
		if (this._routeID != null) {
			request = this._wellService.getRouteWellList(this._routeID, this.page, this.pageSize, this._searchKeyword);
		} else if (this._producerID != null) {
			request = this._wellService.getCustomerWellList(this._producerID, this.page, this.pageSize, this._searchKeyword);
		} else {
			request = this._wellService.getWellList(this.page, this.pageSize, this._searchKeyword);
		}

		this.loading = true;
		this.wells = null;
		this._refreshWellsSub = request.subscribe({
			next: pageData => {
				this.page = pageData.Page;
				this.pageCount = pageData.PageCount;
				this.pageSize = pageData.PageSize;
				this.totalWellCount = pageData.TotalItemCount;
				this.wells = pageData.Items;

				this.loading = false;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.loading = false;
			}
		});
	}

	public onPageChanged(page: number): void {
		if (this.page !== page) {
			this.page = page;
			this.refreshWells();
		}
	}

	private cancelRefreshWells(): void {
		if (this._refreshWellsSub != null) {
			this._refreshWellsSub.unsubscribe();
			this._refreshWellsSub = null;
		}
	}

	public onWellSearchExportFailed(error: IError): void {
		this.errorMsg = `Export failed: ${error.Message}`;
	}
}
