import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { InspectionService, APP_CONFIG, IAppConfig, InputConverterService, IInspectionListItem, IError, IInspection } from 'ee-common-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IScheduleInspectionFailedEvent } from '../schedule-inspection-button/schedule-inspection-button.component';
import { MONTH_YEAR_DATE_FORMAT, ABBREV_MONTH_YEAR_DATE_FORMAT } from 'ee-common-lib';

@Component({
	selector: 'app-requested-inspections',
	templateUrl: './requested-inspections.component.html',
	styleUrls: ['./requested-inspections.component.scss']
})
export class RequestedInspectionsComponent implements OnInit, OnDestroy {
	public loading: boolean;
	public inspections: IInspectionListItem[];
	public inspectionDateFormat: string;
	public page: number;
	public pageCount: number;
	public totalInspectionCount: number;
	public pageSize: number;

	public errorMsg: string;
	public successMsg: string;

	private _queryParamsSub: Subscription;

	constructor(
		private _inspectionService: InspectionService,
		private _inputConverterService: InputConverterService,
		private _route: ActivatedRoute,
		private _router: Router,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
			this.pageSize = this._appConfig.pageSize;
			this.inspectionDateFormat = MONTH_YEAR_DATE_FORMAT;
		}

	ngOnInit() {
		this._queryParamsSub = this._route.queryParamMap.subscribe(queryParams => {
			let page = 1;
			if (queryParams.has('page')) {
				page = InputConverterService.parseInt(queryParams.get('page'));
			}

			if (this.page !== page) {
				this.page = page;
				this.refreshInspections();
			}
		});
	}

	ngOnDestroy() {
		this._queryParamsSub.unsubscribe();
	}

	public onPageChanged(page: number): void {
		this.page = page;
		this._router.navigate([], {
			relativeTo: this._route,
			queryParams: { page},
			queryParamsHandling: 'merge'
		});

		this.refreshInspections();
	}

	public clearErrorMsg(): void {
		this.errorMsg = null;
	}

	public clearSuccessMsg(): void {
		this.successMsg = null;
	}

	public onInspectionScheduled(inspection: IInspection) {
		this.successMsg = `Inspection scheduled for well ${inspection.WellName} for the month of
			${this._inputConverterService.formatDate(inspection.InspectionDate, ABBREV_MONTH_YEAR_DATE_FORMAT) }`;

		this.refreshInspections();
	}

	public onScheduleInspectionFailed(event: IScheduleInspectionFailedEvent) {
		this.errorMsg = `Failed to schedule inspection for well ${event.Inspection.WellName}: ${event.Error.Message}`;
	}

	private refreshInspections(): void {
		this.loading = true;
		this._inspectionService.getRequestedInspectionsList(this.page, this.pageSize).subscribe({
			next: inspectionPage => {
				this.inspections = inspectionPage.Items;
				this.pageCount = inspectionPage.PageCount;
				this.pageSize = inspectionPage.PageSize;
				this.totalInspectionCount = inspectionPage.TotalItemCount;
				this.loading = false;
			},
			error: (error: IError) => {
				this.loading = false;
				this.errorMsg = error.Message;
			}
		});
	}

	public onPreviousInspectionRequested(previousInspection: IInspection) {
		this._router.navigate(['inspection', previousInspection.InspectionID, 'details']);
	}
}
