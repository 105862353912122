import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WellService, ICustomer, ILookupTableItem, IError, IWell, IEditWell, IUpdateWell, EditWellFormComponent } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

interface IRouteData {
	well: IWell;
	producers: ICustomer[];
	states: ILookupTableItem[];
}

@Component({
	selector: 'app-update-well',
	templateUrl: './update-well.component.html',
	styleUrls: ['./update-well.component.scss']
})
export class UpdateWellComponent implements OnInit, OnDestroy {
	@ViewChild(EditWellFormComponent, { static: false })
		private _wellForm: EditWellFormComponent;

	private _routeDataSub: Subscription;

	public well: IEditWell;
	public producers: ICustomer[];
	public states: ILookupTableItem[];

	public submitted: boolean;
	public saving: boolean;

	public errorMsg: string;
	public successMsg: string;

	constructor(private _route: ActivatedRoute, private _wellService: WellService) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.well = this.createEditableWell(data.well);
				this.producers = data.producers;
				this.states = data.states;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch well data: ${error.Message}`;
			}
		});
	}

	private createEditableWell(well: IWell): IEditWell {
		const editableWell: IEditWell = {
			WellID: well.WellID,
			CustomerID: well.CustomerID,
			Description: well.Description,
			Name: well.Name,
			RouteID: well.RouteID,
			State: well.State,
			IsH2s: well.IsH2s
		};
		return editableWell;
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public onReset() {
		this.reset();
	}

	public onSubmit() {
		this.submitted = true;
		this.errorMsg = '';
		this.successMsg = '';

		if (this._wellForm.form.invalid) {
			return;
		}

		this.saving = true;
		const formData = this._wellForm.data();
		const updatedWell: IUpdateWell = {
			WellID: formData.WellID,
			RouteID: formData.RouteID,
			Name: formData.Name,
			Description: formData.Description,
			State: formData.State,
			IsH2s: formData.IsH2s
		};
		this._wellService.updateWell(updatedWell).subscribe({
			next: well => {
				this.well = this.createEditableWell(well);
				this.reset();
				this.saving = false;
				this.successMsg = 'Well was updated successfully';
			},
			error: (error: IError) => {
				this.errorMsg = `Well was not updated: ${error.Message}`;
				this.saving = false;
			}
		});

	}

	public reset() {
		this.submitted = false;
		this._wellForm.reset();
	}
}
