import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ADMIN_APP_CONFIG } from './app.config';
import { ADMIN_AUTH_CONFIG } from './auth.config';

import { APP_CONFIG, AUTH_CONFIG, EeCommonLibModule, JwtInterceptor, ErrorInterceptor } from 'ee-common-lib';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProducersComponent } from './producers/producers.component';
import { RoutesComponent } from './routes/routes.component';
import { WellsComponent } from './wells/wells.component';
import { RecentInspectionsComponent } from './recent-inspections/recent-inspections.component';
import { RequestedInspectionsComponent } from './requested-inspections/requested-inspections.component';
import { InspectionReportsComponent } from './inspection-reports/inspection-reports.component';
import { ScheduledInspectionsComponent } from './scheduled-inspections/scheduled-inspections.component';
import { CreateProducerComponent } from './create-producer/create-producer.component';
import { UpdateProducerComponent } from './update-producer/update-producer.component';
import { CreateRouteComponent } from './create-route/create-route.component';
import { UpdateRouteComponent } from './update-route/update-route.component';
import { UpdateWellComponent } from './update-well/update-well.component';
import { CreateCustomerRepComponent } from './create-customer-rep/create-customer-rep.component';
import { CreateFieldTechComponent } from './create-field-tech/create-field-tech.component';
import { CreateAdminComponent } from './create-admin/create-admin.component';
import { ImportProducerWellsComponent } from './import-producer-wells/import-producer-wells.component';
import { ScheduleInspectionButtonComponent } from './schedule-inspection-button/schedule-inspection-button.component';
import { ScheduleInspectionFormComponent } from './schedule-inspection-form/schedule-inspection-form.component';
import { ApproveInspectionButtonComponent } from './approve-inspection-button/approve-inspection-button.component';
import { UnbilledInspectionsComponent } from './unbilled-inspections/unbilled-inspections.component';
import { CreateUserFormComponent } from './create-user-form/create-user-form.component';
import { UserAccountFormComponent } from './user-account-form/user-account-form.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { EditProducerFormComponent } from './edit-producer-form/edit-producer-form.component';
import { ProducerTableComponent } from './producer-table/producer-table.component';
import { EditRouteFormComponent } from './edit-route-form/edit-route-form.component';
import { RouteTableComponent } from './route-table/route-table.component';
import { WellTableComponent } from './well-table/well-table.component';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { InspectionSearchComponent } from './inspection-search/inspection-search.component';
import { DeleteInspectionButtonComponent } from './delete-inspection-button/delete-inspection-button.component';
import { DeleteWellButtonComponent } from './delete-well-button/delete-well-button.component';
import { DeleteProducerButtonComponent } from './delete-producer-button/delete-producer-button.component';
import { DeleteRouteButtonComponent } from './delete-route-button/delete-route-button.component';
import { RecentInspectionDateComponent } from './recent-inspection-date/recent-inspection-date.component';
import { RecentInspectionHwlComponent } from './recent-inspection-hwl/recent-inspection-hwl.component';
import { NextInspectionDateComponent } from './next-inspection-date/next-inspection-date.component';
import { RoutesNavComponent } from './routes-nav/routes-nav.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { WellOverviewComponent } from './well-overview/well-overview.component';
import { WellNameLinkComponent } from './well-name-link/well-name-link.component';

@NgModule({
	declarations: [
		AppComponent,
		AdminPortalComponent,
		NavbarComponent,
		UserProfileComponent,
		ProducersComponent,
		RoutesComponent,
		WellsComponent,
		RecentInspectionsComponent,
		RequestedInspectionsComponent,
		InspectionReportsComponent,
		ScheduledInspectionsComponent,
		CreateProducerComponent,
		UpdateProducerComponent,
		CreateRouteComponent,
		UpdateRouteComponent,
		UpdateWellComponent,
		CreateCustomerRepComponent,
		CreateFieldTechComponent,
		CreateAdminComponent,
		ImportProducerWellsComponent,
		ScheduleInspectionButtonComponent,
		ScheduleInspectionFormComponent,
		ApproveInspectionButtonComponent,
		UnbilledInspectionsComponent,
		CreateUserFormComponent,
		UserAccountFormComponent,
		ManageUsersComponent,
		EditProducerFormComponent,
		ProducerTableComponent,
		EditRouteFormComponent,
		RouteTableComponent,
		WellTableComponent,
		InspectionDetailsComponent,
		InspectionSearchComponent,
		DeleteInspectionButtonComponent,
		DeleteWellButtonComponent,
		DeleteProducerButtonComponent,
		DeleteRouteButtonComponent,
		RecentInspectionDateComponent,
		RecentInspectionHwlComponent,
		NextInspectionDateComponent,
		RoutesNavComponent,
		SidebarComponent,
		WellOverviewComponent,
		WellNameLinkComponent

	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		EeCommonLibModule,
		HttpClientModule,
		ReactiveFormsModule
	],
	providers: [
		{
			provide: APP_CONFIG,
			useValue: ADMIN_APP_CONFIG
		},
		{
			provide: AUTH_CONFIG,
			useValue: ADMIN_AUTH_CONFIG
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
