import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, IAppConfig, IUser, ICustomerRep } from 'ee-common-lib';
import { ICreateUser } from './create-user.model';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ICreateCustomerRep } from './create-customer-rep.model';
import { IUserListItem } from './user-list-item.model';
import { IResetUserPassword } from './reset-user-password.model';

@Injectable({
	providedIn: 'root'
})
export class UserManagementService {

	constructor(private _http: HttpClient, @Inject(APP_CONFIG) private _appConfig: IAppConfig) {
	}

	public createAdmin(newAdmin: ICreateUser): Observable<IUser> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/user/admin`;
		return this._http.post<IUser>(requestUrl, newAdmin).pipe(shareReplay(1));
	}

	public createFieldTech(newFieldTech: ICreateUser): Observable<IUser> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/user/fieldtech`;
		return this._http.post<IUser>(requestUrl, newFieldTech).pipe(shareReplay(1));
	}

	public createCustomerRep(newCustomerRep: ICreateCustomerRep): Observable<ICustomerRep> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/user/customerrep`;
		return this._http.post<ICustomerRep>(requestUrl, newCustomerRep).pipe(shareReplay(1));
	}

	public getAllUsers(): Observable<IUserListItem[]> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/users/list`;
		return this._http.get<IUserListItem[]>(requestUrl);
	}

	public resetUserPassword(userPasswordReset: IResetUserPassword): Observable<void> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/user/resetpassword`;
		return this._http.put<void>(requestUrl, userPasswordReset);
	}

	public inactivateUser(userID: number): Observable<void> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/user/${userID}/inactivate`;
		return this._http.put<void>(requestUrl, {});
	}
	public deleteUser(userID: number): Observable<void> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/user/${userID}/delete`;
		return this._http.put<void>(requestUrl, {});
	}
}
