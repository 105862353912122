import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, InputConverterService } from 'ee-common-lib';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
	public userFullName: string;
	public userLoginID: string;
	public userIsAuthorized: boolean;
	public isCollapsed = true;

	private _currentUserSubscription: Subscription;
	constructor(private _userService: UserService) {
	}

	ngOnInit() {
		this._currentUserSubscription = this._userService.currentUser$.subscribe(currentUser => {
			this.userIsAuthorized = this._userService.currentUserIsAuthorized();
			if (currentUser != null) {
				this.userFullName = currentUser.Name;
				this.userLoginID = currentUser.LoginID;
			} else {
				this.userFullName = '';
				this.userLoginID = '';
			}
		});
	}

	
	ngOnDestroy() {
		this._currentUserSubscription.unsubscribe();
	}
}
