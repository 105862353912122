import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICustomerListItem } from 'ee-common-lib';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

interface IRouteData {
	producerList: ICustomerListItem[];
}

@Component({
	selector: 'app-producers',
	templateUrl: './producers.component.html',
	styleUrls: ['./producers.component.scss']
})
export class ProducersComponent implements OnInit, OnDestroy {
	private _routeDataSub: Subscription;

	public producers: ICustomerListItem[];

	constructor(private _route: ActivatedRoute) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe((data: IRouteData) => {
			this.producers = data.producerList;
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}
}
