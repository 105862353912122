import { Component, OnInit, ViewChild } from '@angular/core';
import { UserManagementService } from '../user-management.service';
import { CreateUserFormComponent } from '../create-user-form/create-user-form.component';
import { IError } from 'ee-common-lib';

@Component({
	selector: 'app-create-admin',
	templateUrl: './create-admin.component.html',
	styleUrls: ['./create-admin.component.scss']
})
export class CreateAdminComponent implements OnInit {
	@ViewChild(CreateUserFormComponent, { static: false })
		private _userForm: CreateUserFormComponent;

	public submitted: boolean;
	public creating: boolean;
	public successMsg: string;
	public errorMsg: string;

	constructor(private _userMgmtService: UserManagementService) {

	}

	ngOnInit() {
	}

	public onSubmit() {
		this.submitted = true;
		this.successMsg = null;
		this.errorMsg = null;

		if (this._userForm.form.invalid) {
			return;
		}

		this.creating = true;
		const newAdmin = this._userForm.data;

		this._userMgmtService.createAdmin(newAdmin).subscribe({
			next: user => {
				this.reset();
				this.successMsg = `Admin was added successfully`;
				this.creating = false;
			},
			error: (error: IError) => {
				this.errorMsg = `Admin was not added: ${error.Message}`;
				this.creating = false;
			}
		});
	}

	public onReset() {
		this.reset();
	}

	public reset() {
		this.submitted = false;
		this._userForm.reset();
	}
}
