import { Component, OnInit, Input, Inject } from '@angular/core';
import { ICustomer, IRouteListItem, IPage, IError, InputConverterService, APP_CONFIG, IAppConfig, RouteService, CustomerService } from 'ee-common-lib';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
	selector: 'app-route-table',
	templateUrl: './route-table.component.html',
	styleUrls: ['./route-table.component.scss']
})
export class RouteTableComponent implements OnInit {
	@Input() routes: IRouteListItem[];
	public errorMsg: string;
	public successMsg: string;
	public loading: boolean;
	public producers: ICustomer[];
	public searchForm: FormGroup;
	private _routeParamMapSub: Subscription;
	private _refreshRoutesSub: Subscription;
	constructor(private _route: ActivatedRoute,
		private _router: Router,
		private _routeService: RouteService,
		private _customerService: CustomerService,
		private _formBuilder: FormBuilder) { }

	ngOnInit() {
		
		let main = document.querySelector("#main_content");
		main.classList.add("col-lg-9")
		main.classList.remove("col-lg-12")
		this.searchForm = this._formBuilder.group({
			CustomerID: ['']
		});

		this.loading = true;
		this._customerService.getAllCustomers().subscribe({
			next: producers => {
				this.producers = producers;

				const snapshot = this._route.snapshot;
				this.parseRouteParams(snapshot.paramMap);
				this.subscribeToRouteParams();

				// fetch routes if the customer ID has been set, otherwise set the default customer ID
				if (this.selectedCustomerID != null) {
					this.refreshRoutes();
				} else {
					this.selectDefaultCustomerID();
				}
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});
	}

	private parseRouteParams(paramMap: ParamMap): boolean {
		if (paramMap == null) {
			return false;
		}

		const customerID = InputConverterService.parseInt(paramMap.get('customerID'));
		return this.setSelectedCustomerID(customerID);
	}

	private setSelectedCustomerID(customerID: number): boolean {
		const prevCustomerID = InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);
		this.searchForm.controls.CustomerID.setValue(customerID != null ? customerID.toString() : '');
		return customerID !== prevCustomerID;
	}

	private subscribeToRouteParams() {
		this._routeParamMapSub = this._route.paramMap.subscribe(paramMap => {
			if (this.parseRouteParams(paramMap)) {
				if (this.selectedCustomerID != null) {
					this.refreshRoutes();
				} else {
					this.selectDefaultCustomerID();
				}
			}
		});
	}
	private selectDefaultCustomerID() {
		const defaultCustomerID = this.defaultCustomerID;
		this.setSelectedCustomerID(defaultCustomerID);
	}

	private get defaultCustomerID(): number {
		if (this.producers == null || this.producers.length === 0) {
			return null;
		}

		return this.producers[0].CustomerID;
	}

	public getRouteID(route: IRouteListItem): number {
		return route.RouteID;
	}

	public onEditRoute(routeID: number) {
		this._router.navigate(['route', routeID, 'edit' ]);
	}
	public onRouteActionFailed(error: IError) {
		this.errorMsg = error.Message;
	}

	public onRouteDeleted(route: IRouteListItem) {
		this.successMsg = `Route ${route.Name} deleted successfully`;
		this.refreshRoutes();
	}
	public get selectedCustomerID(): number {
		return InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);
	}
	public refreshRoutes() {
		this.cancelRefreshRoutes();
		const customerID = this.selectedCustomerID;

		if (customerID == null) {
			this.routes = [];
			this.loading = false;
			return;
		}

		this.loading = true;
		this._refreshRoutesSub = this._routeService.getCustomerRouteList(customerID).subscribe({
			next: routes => {
				this.routes = routes;
				this.loading = false;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.loading = false;
			}
		});
	}

	private cancelRefreshRoutes() {
		if (this._refreshRoutesSub != null) {
			this._refreshRoutesSub.unsubscribe();
			this._refreshRoutesSub = null;
		}
	}

	public getCustomerID(producer: ICustomer): number {
		return producer.CustomerID;
	}
}
