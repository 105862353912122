import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { InspectionService, InputConverterService, APP_CONFIG, IAppConfig,
	IInspectionListItem, IPage, IError, DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE,
	ICustomer, ICustomerInspectionSearch } from 'ee-common-lib';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

export const DEFAULT_INSPECTION_SEARCH_REPORT_NAME = 'Inspection Reports';

interface IRouteData {
	producers: ICustomer[];
}

@Component({
	selector: 'app-inspection-reports',
	templateUrl: './inspection-reports.component.html',
	styleUrls: ['./inspection-reports.component.scss']
})
export class InspectionReportsComponent implements OnInit, OnDestroy {
	private _routeParamsSub: Subscription;
	private _routeQueryParamsSub: Subscription;
	private _routeDataSub: Subscription;
	private _inspectionsSub: Subscription;

	public producers: ICustomer[];

	public loading: boolean;
	public customerID: number;
	public keyword: string;
	public searchTerm: string;
	public numDays: number;

	public inspectionPage: IPage<IInspectionListItem>;
	public page = 1;

	public inspectionSearchExportFileName = DEFAULT_INSPECTION_SEARCH_REPORT_NAME;
	public errorMsg: string;
	public successMsg: string;

	constructor(
		private _inspectionService: InspectionService,
		private _route: ActivatedRoute,
		private _router: Router,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
			this.inspectionPage = {
				Items: [],
				Page: 1,
				PageCount: 0,
				PageSize: this._appConfig.pageSize,
				TotalItemCount: 0
			};
		}

	ngOnInit() {
		this._routeParamsSub = this._route.paramMap.subscribe(paramMap => {
			if (this.parseRouteParams(paramMap)) {
				this.refreshInspections();
			}
		});

		this._routeQueryParamsSub = this._route.queryParamMap.subscribe(queryParamMap => {
			if (this.parseRouteQueryParams(queryParamMap)) {
				this.refreshInspections();
			}
		});

		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.producers = data.producers;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch route data: ${error.Message}`;
			}
		});
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		this._routeQueryParamsSub.unsubscribe();
		this._routeDataSub.unsubscribe();
		this.cancelRefreshInspections();
	}

	public refreshInspections() {
		this.cancelRefreshInspections();

		this.loading = true;
		this.inspectionPage = Object.assign({}, this.inspectionPage, { Items: [] });

		const inspectionsRequest = this.requestInspections();

		this._inspectionsSub = inspectionsRequest.subscribe({
			next: page => {
				this.inspectionPage = page;
				this.loading = false;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.loading = false;
			}
		});
	}

	private requestInspections(): Observable<IPage<IInspectionListItem>> {
		if (this.customerID != null) {
			return this._inspectionService.getCustomerInspectionList(this.customerID, this.keyword,
				this.numDays, this.searchTerm, this.page, this.inspectionPage.PageSize);
		} else {
			return this._inspectionService.getInspectionList(this.keyword, this.numDays, this.searchTerm, this.page,
				this.inspectionPage.PageSize);
		}
	}

	private cancelRefreshInspections() {
		if (this._inspectionsSub != null) {
			this._inspectionsSub.unsubscribe();
			this._inspectionsSub = null;
		}
	}

	private parseRouteParams(paramMap: ParamMap): boolean {
		if (paramMap == null) {
			return;
		}

		const numDays = InputConverterService.parseInt(paramMap.get('numDays'));

		const hasChanged = this.numDays !== numDays;
		this.numDays = numDays;

		return hasChanged;
	}

	private parseRouteQueryParams(queryParamMap: ParamMap): boolean {
		let keyword = '';
		if (queryParamMap.has('keyword')) {
			keyword = queryParamMap.get('keyword');
		}

		let searchTerm = '';
		if (queryParamMap.has('searchTerm')) {
			searchTerm = queryParamMap.get('searchTerm');
		}

		let customerID: number = null;
		if (queryParamMap.has('customerID')) {
			customerID = InputConverterService.parseInt(queryParamMap.get('customerID'));
		}

		let page: number = null;
		if (queryParamMap.has('page')) {
			page = InputConverterService.parseInt(queryParamMap.get('page'));
		}

		if (page == null) {
			page = 1;
		}

		const prevPage = this.page;
		this.page = page;

		const searchParamsChanged = keyword !== this.keyword || customerID !== this.customerID || searchTerm != this.searchTerm;
		this.keyword = keyword;
		this.customerID = customerID;
		this.searchTerm = searchTerm;
		
		return searchParamsChanged || page !== prevPage;
	}

	public onEditInspection(inspectionID: number): void {
		this._router.navigate(['inspection', inspectionID, 'edit']);
	}

	public onInspectionSearchExportFailed(error: IError): void {
		this.errorMsg = `Export failed: ${error.Message}`;
	}

	public onInspectionDeleted(inspection: IInspectionListItem) {
		this.successMsg = `Inspection for well ${inspection.WellName} deleted successfully`;
		this.refreshInspections();
	}

	public onInspectionActionFailed(error: IError) {
		this.errorMsg = error.Message;
	}

	public onSearch(searchParams: ICustomerInspectionSearch) {
		this.customerID = searchParams.CustomerID;
		this.numDays = searchParams.NumDays;
		this.keyword = searchParams.Keyword;
		this.searchTerm = searchParams.VesselID;
		this.refreshInspections();

		const queryParams: {page: number, keyword?: string, customerID?: string, searchTerm?: string} = {
			page: this.page
		};

		if (!InputConverterService.isEmpty(searchParams.Keyword)) {
			queryParams.keyword = searchParams.Keyword;
		}

		if (!InputConverterService.isEmpty(searchParams.VesselID)) {
			queryParams.searchTerm = searchParams.VesselID;
		}

		if (searchParams.CustomerID != null) {
			queryParams.customerID = searchParams.CustomerID.toString();
		}

		this._router.navigate(['export/inspectionreports', searchParams.NumDays], { queryParams });
	}

	public onPageChanged(page: number): void {
		this._router.navigate([], {
			queryParams: {
				page
			},
			queryParamsHandling: 'merge'
		});
	}

	public get hasNumDays(): boolean {
		return this.numDays > 0;
	}
}
