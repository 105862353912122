import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { RouteTreeService, IInspection, IWell, IPage, InputConverterService,
	IError } from 'ee-common-lib';

interface IWellOverviewRouteData {
	page: IPage<IInspection>;
	well: IWell;
}

@Component({
	selector: 'app-well-overview',
	templateUrl: './well-overview.component.html',
	styleUrls: ['./well-overview.component.scss']
})
export class WellOverviewComponent implements OnInit, OnDestroy {
	public inspectionPage: IPage<IInspection>;
	public loading: boolean;

	public well: IWell;

	public errorMsg: string;
	public successMsg: string;

	private _routeParamsSub: Subscription;
	private _routeDataSub: Subscription;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _routesService: RouteTreeService) {
		}

	private onParamsChanged = (paramMap: ParamMap) => {
		const routeID = InputConverterService.parseInt(paramMap.get('routeID'));
		const wellID = InputConverterService.parseInt(paramMap.get('wellID'));

		this.updateSelectedParams(routeID, wellID);
	}

	private updateSelectedParams(routeID: number, wellID: number) {
		this._routesService.setSelectedRouteID(routeID);
		this._routesService.setSelectedWellID(wellID);
	}

	ngOnInit() {
		this.loading = true;
		this._routeParamsSub = this._route.paramMap.subscribe(this.onParamsChanged);

		this._routeDataSub = this._route.data.subscribe({
			next: this.onRouteDataChanged,
			error: (error: IError) => {
				this.loading = false;
				this.errorMsg = error.Message;
			}
		});
	}

	private onRouteDataChanged = (routeData: IWellOverviewRouteData): void => {
		this.errorMsg = '';
		this.loading = false;

		this.well = routeData.well;
		this.inspectionPage = routeData.page;
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		this._routeDataSub.unsubscribe();
	}

	public onPageChanged(page: number) {
		this.loading = true;
		this._router.navigate(['./', { page }], { relativeTo: this._route });
	}

	public onInspectionExportFailed(error: IError): void {
		this.errorMsg = `Export failed: ${error.Message}`;
	}
}
