import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IPage, IInspection, InspectionService, InputConverterService, IAppConfig, APP_CONFIG } from 'ee-common-lib';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class WellInspectionsPageResolverService implements Resolve<IPage<IInspection>> {

	constructor(
		private _inspectionService: InspectionService,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPage<IInspection>> {
		const wellID = InputConverterService.parseInt(route.paramMap.get('wellID'));

		let page = 1;
		if (route.paramMap.has('page')) {
			page = InputConverterService.parseInt(route.paramMap.get('page'));
		}

		return this._inspectionService.getWellInspections(wellID, 'NA', page, this._appConfig.pageSize);
	}
}
