import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AUTHENTICATION, ValueMatchValidator, PasswordMatchFormComponent } from 'ee-common-lib';
import { ICreateUser } from '../create-user.model';

export interface IUserAccountForm {
	LoginID: string;
	PasswordMatch: {
		Password: string;
		ConfirmPassword: string;
	};
}

@Component({
	selector: 'app-user-account-form',
	templateUrl: './user-account-form.component.html',
	styleUrls: ['./user-account-form.component.scss']
})
export class UserAccountFormComponent implements OnInit, AfterViewInit {
	@ViewChild(PasswordMatchFormComponent, { static: false })
	private _passwordForm: PasswordMatchFormComponent;

	@Input() showErrors: boolean;

	public form: FormGroup;

	constructor(private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.form = this._formBuilder.group({
			LoginID: ['', [Validators.required, Validators.email] ]
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.form.addControl('PasswordMatch', this._passwordForm.form);
		});
	}

	public get controls() {
		return this.form.controls;
	}

	public reset() {
		this.form.reset();
	}

	public get data(): IUserAccountForm {
		return this.form.value;
	}
}
