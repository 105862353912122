import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IError, IInspection, IInspectionListItem, InspectionService } from 'ee-common-lib';

export interface IInspectionApprovalFailedEvent {
	Inspection: IInspection | IInspectionListItem;
	Error: IError;
}

@Component({
	selector: 'app-approve-inspection-button',
	templateUrl: './approve-inspection-button.component.html',
	styleUrls: ['./approve-inspection-button.component.scss']
})
export class ApproveInspectionButtonComponent implements OnInit {

	@Input() inspection: IInspection | IInspectionListItem;
	@Output() inspectionApproved: EventEmitter<IInspection>;
	@Output() inspectionApprovalFailed: EventEmitter<IInspectionApprovalFailedEvent>;

	public approvingInspection: boolean;

	constructor(private _inspectionService: InspectionService) {
		this.inspectionApproved = new EventEmitter<IInspection>();
		this.inspectionApprovalFailed = new EventEmitter<IInspectionApprovalFailedEvent>();
	}

	ngOnInit() {
	}

	public onRequestApproval(): void {
		const confirmMsg = `Approve inspection for well ${this.inspection.WellName}?`;

		if (!confirm(confirmMsg)) {
			return;
		}

		this.approvingInspection = true;
		const request = this._inspectionService.approveInspection(this.inspection.InspectionID);
		request.subscribe({
			next: inspection => {
				this.approvingInspection = false;
				this.inspectionApproved.emit(inspection);
			},
			error: (error: IError) => {
				this.approvingInspection = false;
				this.inspectionApprovalFailed.emit({
					Inspection: this.inspection,
					Error: error
				});
			}
		});
	}

}
