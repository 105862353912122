import { Component, OnInit, Input } from '@angular/core';
import { IWell, WellService, ActionButton, IError, IWellListItem } from 'ee-common-lib';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Component({
	selector: 'app-delete-well-button',
	templateUrl: './delete-well-button.component.html',
	styleUrls: ['./delete-well-button.component.scss']
})
export class DeleteWellButtonComponent extends ActionButton<IWell | IWellListItem> implements OnInit {
	@Input() well: IWell | IWellListItem;

	constructor(private _wellService: WellService) {
		super();
	}

	public ngOnInit(): void {
	}

	protected get canExecute(): boolean {
		return this.well !== null;
	}

	protected confirmExecute(): boolean {
		const confirmDelete = confirm('Delete well?');
		return confirmDelete;
	}

	protected execute(): Observable<IWell | IWellListItem> {
		return this._wellService.deleteWell(this.well.WellID).pipe(map(() => {
			return this.well;
		}), catchError((error: IError) => {
			const errorMsg = `Failed to delete well ${this.well.Name}: ${error.Message}`;
			error = {
				Message: errorMsg
			};

			return throwError(error);
		}));
	}
}
