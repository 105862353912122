import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {  EditContactFormComponent } from 'ee-common-lib';
import { UserAccountFormComponent } from '../user-account-form/user-account-form.component';
import { ICreateUser } from '../create-user.model';

@Component({
	selector: 'app-create-user-form',
	templateUrl: './create-user-form.component.html',
	styleUrls: ['./create-user-form.component.scss']
})
export class CreateUserFormComponent implements OnInit, AfterViewInit {
	@ViewChild(UserAccountFormComponent, { static: false })
	private _accountForm: UserAccountFormComponent;

	@ViewChild(EditContactFormComponent, { static: false })
	private _contactForm: EditContactFormComponent;

	@Input() showErrors: boolean;

	public form: FormGroup;

	constructor(private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.form = this._formBuilder.group({});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.form.addControl('User', this._accountForm.form);
			this.form.addControl('Contact', this._contactForm.form);
		});
	}

	public reset() {
		this.form.reset();
	}

	public get data(): ICreateUser {
		const userData = this._accountForm.data;
		const contactData = this._contactForm.data;

		const formData: ICreateUser = {
			LoginID: userData.LoginID,
			Password: userData.PasswordMatch.Password,
			ConfirmPassword: userData.PasswordMatch.ConfirmPassword,
			Contact: contactData
		};

		return formData;
	}
}
