import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UserManagementService } from '../user-management.service';
import { IError, InputConverterService, PasswordMatchFormComponent, UserService } from 'ee-common-lib';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { IUserListItem } from '../user-list-item.model';
import { IResetUserPassword } from '../reset-user-password.model';
import { map } from 'rxjs/operators';
import { IUser } from '../../../../ee-common-lib/src/public-api';
import { IContact } from '../../../../ee-common-lib/src/lib/models/contact.model';
import { IUpdateUser } from '../../../../ee-common-lib/src/lib/models/update-user.model';

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, AfterViewInit {
	@ViewChild(PasswordMatchFormComponent, { static: false })
	private _passwordForm: PasswordMatchFormComponent;

	public loading: boolean;

	public form: FormGroup;
	public submitted: boolean;
	public resettingUserPassword: boolean;
	public updatingUser: boolean;
	public disablingUserAccount: boolean;
	public deleteUserAccount: boolean;
	public successMsg: string;
	public errorMsg: string;
	public email: string;
	public firstName: string;
	public lastName: string;
	public users: IUserListItem[];
	public user: IUser;
	public userId: number;
	public contact: IContact;
	public showErrors: boolean;
	Email: string = '';
	constructor(
		private _userMgmtService: UserManagementService, private _userService: UserService,
		private _formBuilder: FormBuilder, private _formsModule:FormsModule) {
	}

	ngOnInit() {
		this.form = this._formBuilder.group({
			UserID: ['', Validators.required]
		});

		this.refreshUsers();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.form.addControl('PasswordMatch', this._passwordForm.form);
		});
	}

	public get controls() {
		return this.form.controls;
	}

	public getUserID(user: IUserListItem): number {
		this.email = user.LoginID;
		return user.UserID;
	}

	selectEvent(userID: string) {
		this._userService.getContact(Number(userID)).subscribe(c => {
			this.firstName = c.FirstName;
			this.lastName = c.LastName;
			this.email = c.EmailAddress;
			this.userId = Number(userID);
		});
	}

	public getUserDisplayName(user: IUserListItem): string {
		let displayName: string;
		if (InputConverterService.isEmpty(user.Name)) {
			displayName = user.LoginID;
		} else {
			displayName = `${user.Name} (${user.LoginID})`;
		}
		
		if (user.IsInactive) {
			displayName += ' *';
		}
		return displayName;
	}

	public refreshUsers(): void {
		this.loading = true;
		this.users = [];

		this._userMgmtService.getAllUsers().pipe(map(users => {
			return users.filter(user => {
				return !user.IsInactive;
			});
		})).subscribe({
			next: users => {
				this.users = users;
				this.loading = false;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch user list: ${error.Message}`;
				this.loading = false;
			}
		});
	}

	public onEmailChange(e:string) {
		this.email = e;
	}

	public onFNameChange(f: string) {
		this.firstName = f;
	}

	public onLNameChange(l: string) {
		this.lastName = l;
	}
	public onUpdateInfo() {
		this.submitted = true;
		this.errorMsg = '';
		this.successMsg = '';

		if (this.form.invalid) {
			return;
		}

		const updatedContact: IUpdateUser = {
			Email: this.email,
			UserID: this.userId,
			FirstName: this.firstName,
			LastName: this.lastName
		};

		this._userService.updateUser(updatedContact, this.userId, this.email).subscribe({
			next: contact => {
				this.submitted = false;
				this.successMsg = 'User was updated successfully';
				this.refreshUsers();
			},
			error: (error: IError) => {
				this.errorMsg = `User was not updated: ${error.Message}`;
				this.submitted = false;
			}
		});
		
	}

	public onResetUserPassword() {
		this.submitted = true;
		this.successMsg = null;
		this.errorMsg = null;

		if (this.form.invalid) {
			return;
		}

		this.resettingUserPassword = true;
		const passwordReset = this.passwordResetData;

		this._userMgmtService.resetUserPassword(passwordReset).subscribe({
			next: () => {
				this.reset();
				this.successMsg = `User password reset successfully`;
				this.resettingUserPassword = false;
			},
			error: (error: IError) => {
				this.errorMsg = `User password reset failed: ${error.Message}`;
				this.resettingUserPassword = false;
			}
		});
	}

	public onReset() {
		this.reset();
	}

	public reset() {
		this.submitted = false;
		this.form.reset();
	}

	public get passwordResetData(): IResetUserPassword {
		const userID = InputConverterService.parseInt(this.controls.UserID.value);
		const passwordMatch = this._passwordForm.data;

		const passwordReset: IResetUserPassword = {
			UserID: userID,
			NewPassword: passwordMatch.Password,
			ConfirmNewPassword: passwordMatch.ConfirmPassword
		};

		return passwordReset;
	}

	public onDisableUserAccount(): void {
		const userID = InputConverterService.parseInt(this.controls.UserID.value);
		if (userID == null) {
			alert('No user selected');
			return;
		}

		if (confirm('Disable selected user account?')) {
			this.disablingUserAccount = true;
			this._userMgmtService.inactivateUser(userID).subscribe({
				next: () => {
					this.successMsg = 'User account disabled successfully';
					this.disablingUserAccount = false;
					this.refreshUsers();
				},
				error: (error: IError) => {
					this.errorMsg = `Failed to disable user account: ${error.Message}`;
					this.disablingUserAccount = false;
				}
			});
		}
	}
	public onDeleteUserAccount(): void {
		const userID = InputConverterService.parseInt(this.controls.UserID.value);
		if (userID == null) {
			alert('No user selected');
			return;
		}

		if (confirm('Delete selected user account?')) {
			this.deleteUserAccount = true;
			this._userMgmtService.deleteUser(userID).subscribe({
				next: () => {
					this.successMsg = 'User account deleted successfully';
					this.deleteUserAccount = false;
					this.refreshUsers();
				},
				error: (error: IError) => {
					this.errorMsg = `Failed to delete user account: ${error.Message}`;
					this.deleteUserAccount = false;
				}
			});
		}
	}
}
