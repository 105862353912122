import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, ChangePasswordComponent, CustomersResolverService,
	CustomerResolverService, RouteResolverService, WellResolverService, InspectionResolverService,
	CreateWellComponent, StatesResolverService, UpdateInspectionComponent,
	EditInspectionResolverService, DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE} from 'ee-common-lib';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProducersComponent } from './producers/producers.component';
import { RoutesComponent } from './routes/routes.component';
import { WellsComponent } from './wells/wells.component';
import { RecentInspectionsComponent } from './recent-inspections/recent-inspections.component';
import { RequestedInspectionsComponent } from './requested-inspections/requested-inspections.component';
import { ScheduledInspectionsComponent } from './scheduled-inspections/scheduled-inspections.component';
import { CreateProducerComponent } from './create-producer/create-producer.component';
import { UpdateProducerComponent } from './update-producer/update-producer.component';
import { CreateRouteComponent } from './create-route/create-route.component';
import { UpdateRouteComponent } from './update-route/update-route.component';
import { UpdateWellComponent } from './update-well/update-well.component';
import { CreateCustomerRepComponent } from './create-customer-rep/create-customer-rep.component';
import { CreateFieldTechComponent } from './create-field-tech/create-field-tech.component';
import { CreateAdminComponent } from './create-admin/create-admin.component';
import { ImportProducerWellsComponent } from './import-producer-wells/import-producer-wells.component';
import { UnbilledInspectionsComponent,
	DEFAULT_UNBILLED_INSPECTIONS_DAYS_BEFORE } from './unbilled-inspections/unbilled-inspections.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { CustomerListResolverService } from './customer-list-resolver.service';
import { InspectionDetailsComponent } from './inspection-details/inspection-details.component';
import { InspectionSearchComponent } from './inspection-search/inspection-search.component';
import { InspectionReportsComponent } from './inspection-reports/inspection-reports.component';
import { WellOverviewComponent } from './well-overview/well-overview.component';
import { WellInspectionsPageResolverService } from './well-inspections-page-resolver.service';

const routes: Routes = [
	{
		path: 'login',
		component: AdminPortalComponent
	},
	{
		path: '',
		canActivate: [AuthGuard],
		component: RecentInspectionsComponent,
		pathMatch: 'full'
	},
	{
		path: 'inspections/requested',
		component: RequestedInspectionsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'inspections/scheduled',
		canActivate: [AuthGuard],
		component: ScheduledInspectionsComponent
	},
	{
		path: 'inspections/scheduled/:year/:month',
		canActivate: [AuthGuard],
		component: ScheduledInspectionsComponent
	},
	{
		path: 'inspections/unbilled',
		canActivate: [AuthGuard],
		redirectTo: `inspections/unbilled/${DEFAULT_UNBILLED_INSPECTIONS_DAYS_BEFORE}`
	},
	{
		path: 'inspections/unbilled/:numDays',
		canActivate: [AuthGuard],
		component: UnbilledInspectionsComponent
	},
	{
		path: 'inspections/search',
		redirectTo: `inspections/search/${DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE}`,
		pathMatch: 'full'
	},
	{
		path: 'inspections/search/:numDays',
		component: InspectionSearchComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'inspection/:inspectionID/details',
		component: InspectionDetailsComponent,
		canActivate: [AuthGuard],
		resolve: {
			inspection: InspectionResolverService
		}
	},
	{
		path: 'inspection/:inspectionID/edit',
		component: UpdateInspectionComponent,
		canActivate: [AuthGuard],
		resolve: {
			editInspection: EditInspectionResolverService
		}
	},
	{
		path: 'producers',
		component: ProducersComponent,
		canActivate: [AuthGuard],
		resolve: {
			producerList: CustomerListResolverService
		}
	},
	{
		path: 'producer/add',
		component: CreateProducerComponent,
		canActivate: [AuthGuard],
		resolve: {
			states: StatesResolverService
		}
	},
	{
		path: 'producer/:producerID/edit',
		component: UpdateProducerComponent,
		canActivate: [AuthGuard],
		resolve: {
			producer: CustomerResolverService,
			states: StatesResolverService
		}
	},
	{
		path: 'import/producerwells',
		component: ImportProducerWellsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'export/inspectionreports',
		redirectTo: `export/inspectionreports/${DEFAULT_INSPECTION_SEARCH_DAYS_BEFORE}`,
		pathMatch: 'full'
	},
	{
		path: 'export/inspectionreports/:numDays',
		component: InspectionReportsComponent,
		canActivate: [AuthGuard],
		resolve: {
			producers: CustomersResolverService,
		}
	},
	{
		path: 'routes',
		redirectTo: 'routes/',
		pathMatch: 'full',
		canActivate: [AuthGuard]
	},
	{
		path: 'routes/:routeID/wells/:wellID',
		component: WellOverviewComponent,
		canActivate: [AuthGuard],
		resolve: {
			well: WellResolverService,
			page: WellInspectionsPageResolverService
		}
	},
	{
		path: 'routes/:customerID',
		component: RoutesComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'route/add',
		component: CreateRouteComponent,
		canActivate: [AuthGuard],
		resolve: {
			producers: CustomersResolverService,
			states: StatesResolverService
		}
	},
	{
		path: 'route/:routeID/edit',
		component: UpdateRouteComponent,
		canActivate: [AuthGuard],
		resolve: {
			route: RouteResolverService,
			producers: CustomersResolverService,
			states: StatesResolverService
		}
	},
	{
		path: 'wells',
		component: WellsComponent,
		canActivate: [AuthGuard],
		resolve: {
			producers: CustomersResolverService
		}
	},
	{
		path: 'well/add',
		component: CreateWellComponent,
		canActivate: [AuthGuard],
		resolve: {
			producers: CustomersResolverService,
			states: StatesResolverService
		}
	},
	{
		path: 'well/:wellID/edit',
		component: UpdateWellComponent,
		canActivate: [AuthGuard],
		resolve: {
			well: WellResolverService,
			producers: CustomersResolverService,
			states: StatesResolverService
		}
	},
	{
		path: 'user/customerrep/add',
		component: CreateCustomerRepComponent,
		canActivate: [AuthGuard],
		resolve: {
			customers: CustomersResolverService
		}
	},
	{
		path: 'user/fieldtech/add',
		component: CreateFieldTechComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'user/admin/add',
		component: CreateAdminComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'users/manage',
		component: ManageUsersComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile',
		component: UserProfileComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile/changePassword',
		component: ChangePasswordComponent,
		canActivate: [AuthGuard]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
