import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ILookupTableItem, ICustomer, IBaseEditRoute, InputConverterService } from 'ee-common-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface IFormData {
	CustomerID: string;
	Name: string;
	Description: string;
	StatesServed: string[];
}

@Component({
	selector: 'app-edit-route-form',
	templateUrl: './edit-route-form.component.html',
	styleUrls: ['./edit-route-form.component.scss']
})
export class EditRouteFormComponent implements OnInit, OnChanges {
	@Input() route: IBaseEditRoute;
	@Input() producers: ICustomer[];
	@Input() states: ILookupTableItem[];
	@Input() showErrors: boolean;

	public form: FormGroup;

	constructor(private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.form = this._formBuilder.group({
			CustomerID: ['', [ Validators.required ]],
			Name: ['', [ Validators.required, Validators.maxLength(100) ]],
			Description: [''],
			StatesServed: ['']
		});

		this.reset();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes == null || this.form == null) {
			return;
		}

		if (changes.route != null && changes.route.previousValue !== changes.route.currentValue) {
			this.reset();
		}
	}

	public getIndex(item: ILookupTableItem): string {
		return item.Index;
	}

	public getCustomerID(producer: ICustomer): number {
		return producer.CustomerID;
	}

	public get controls() {
		return this.form.controls;
	}

	public reset(): void {
		if (this.form == null) {
			return;
		}

		const formData: IFormData = {
			CustomerID: this.route != null && this.route.CustomerID != null ? this.route.CustomerID.toString() : '',
			Name: this.route != null ? this.route.Name : '',
			Description: this.route != null ? this.route.Description : '',
			StatesServed: this.route != null ? this.route.StatesServed : []
		};

		this.form.reset(formData);
	}

	public data(): IBaseEditRoute {
		const route: IBaseEditRoute = {
			CustomerID: InputConverterService.parseInt(this.controls.CustomerID.value),
			Name: InputConverterService.trim(this.controls.Name.value),
			Description: InputConverterService.trim(this.controls.Description.value),
			StatesServed: this.controls.StatesServed.value
		};

		return route;
	}
}
