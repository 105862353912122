import { Injectable } from '@angular/core';
import { ICustomerListItem, CustomerService } from 'ee-common-lib';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CustomerListResolverService implements Resolve<ICustomerListItem[]> {

	constructor(private _customerService: CustomerService) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICustomerListItem[]> {
		return this._customerService.getCustomerList();
	}
}
