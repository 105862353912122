import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ILookupTableItem, CustomerService, IError } from 'ee-common-lib';
import { EditProducerFormComponent } from '../edit-producer-form/edit-producer-form.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

interface IRouteData {
	states: ILookupTableItem[];
}

@Component({
	selector: 'app-create-producer',
	templateUrl: './create-producer.component.html',
	styleUrls: ['./create-producer.component.scss']
})
export class CreateProducerComponent implements OnInit, OnDestroy {
	@ViewChild(EditProducerFormComponent, { static: false })
		private _producerForm: EditProducerFormComponent;

	private _routeDataSub: Subscription;

	public submitted: boolean;
	public creating: boolean;
	public successMsg: string;
	public errorMsg: string;

	public states: ILookupTableItem[];

	constructor(private _customerService: CustomerService, private _route: ActivatedRoute) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.states = data.states;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch producer data: ${error.Message}`;
			}
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public onReset() {
		this.reset();
	}

	public onSubmit() {
		this.submitted = true;
		this.errorMsg = '';
		this.successMsg = '';

		if (this._producerForm.form.invalid) {
			return;
		}

		this.creating = true;
		const customer = this._producerForm.data();

		this._customerService.createCustomer(customer).subscribe({
			next: newCustomer => {
				this.reset();
				this.creating = false;
				this.successMsg = 'Producer was added successfully';
			},
			error: (error: IError) => {
				this.errorMsg = `Producer was not added: ${error.Message}`;
				this.creating = false;
			}
		});
	}

	public reset() {
		this.submitted = false;
		this._producerForm.reset();
	}
}
