import { Component, OnInit } from '@angular/core';
import { ImportService } from '../import.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileExtValidator, IError } from 'ee-common-lib';
import { ICustomerWellImportSummary } from '../customer-well-import-summary.model';

@Component({
	selector: 'app-import-producer-wells',
	templateUrl: './import-producer-wells.component.html',
	styleUrls: ['./import-producer-wells.component.scss']
})
export class ImportProducerWellsComponent implements OnInit {
	public supportedExtensions: string[] = ['.csv'];

	public form: FormGroup;

	public submitted: boolean;
	public importing: boolean;
	public errorMsg: string;
	public importSummary: ICustomerWellImportSummary;

	private _producerWellsFile: File;

	constructor(
		private _importService: ImportService,
		private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.form = this._formBuilder.group({
			ProducerWellsFileName: ['', [Validators.required, FileExtValidator(this.supportedExtensions)]]
		});
	}

	public get controls() {
		return this.form.controls;
	}

	private refreshProducerWellsDoc(file: File) {
		if (this.controls.ProducerWellsFileName.errors) {
			this._producerWellsFile = null;
		} else {
			this._producerWellsFile = file;
		}
	}

	public onProducerWellsFileChanged(event: any) {
		if (!event.target.files || event.target.files.length === 0) {
			return;
		}

		const selectedFile: File = event.target.files[0];
		this.refreshProducerWellsDoc(selectedFile);
	}

	public onSubmit(): void {
		this.submitted = true;
		this.errorMsg = null;
		this.importSummary = null;

		if (this.form.invalid) {
			return;
		}

		this.importing = true;
		this._importService.importCustomerWells(this._producerWellsFile).subscribe({
			next: importSummary => {
				this.importSummary = importSummary;
				this.importing = false;
				this.resetForm();
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.importing = false;
			}
		});
	}

	public onReset(): void {
		this.resetForm();
		this.errorMsg = null;
		this.importSummary = null;
	}

	private resetForm(): void {
		this.submitted = false;
		this.form.reset();
	}
}
