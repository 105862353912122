import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICustomer, IRouteListItem, RouteService, IError, InputConverterService, CustomerService } from 'ee-common-lib';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
	selector: 'app-routes',
	templateUrl: './routes.component.html',
	styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit, OnDestroy {
	private _routeParamMapSub: Subscription;
	private _refreshRoutesSub: Subscription;

	public searchForm: FormGroup;

	public producers: ICustomer[];
	public routes: IRouteListItem[];

	public successMsg: string;
	public errorMsg: string;
	public loading: boolean;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _routeService: RouteService,
		private _customerService: CustomerService,
		private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.searchForm = this._formBuilder.group({
			CustomerID: ['']
		});

		this.loading = true;
		this._customerService.getAllCustomers().subscribe({
			next: producers => {
				this.producers = producers;

				const snapshot = this._route.snapshot;
				this.parseRouteParams(snapshot.paramMap);
				this.subscribeToRouteParams();

				// fetch routes if the customer ID has been set, otherwise set the default customer ID
				if (this.selectedCustomerID != null) {
					this.refreshRoutes();
				} else {
					this.selectDefaultCustomerID();
				}
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});
	}

	private selectDefaultCustomerID() {
		const defaultCustomerID = this.defaultCustomerID;
		this.setSelectedCustomerID(defaultCustomerID);
		this.onCustomerChanged(defaultCustomerID != null ? defaultCustomerID.toString() : '');
	}

	private get defaultCustomerID(): number {
		if (this.producers == null || this.producers.length === 0) {
			return null;
		}

		return this.producers[0].CustomerID;
	}

	private parseRouteParams(paramMap: ParamMap): boolean {
		if (paramMap == null) {
			return false;
		}

		const customerID = InputConverterService.parseInt(paramMap.get('customerID'));
		return this.setSelectedCustomerID(customerID);
	}

	private setSelectedCustomerID(customerID: number): boolean {
		const prevCustomerID = InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);
		this.searchForm.controls.CustomerID.setValue(customerID != null ? customerID.toString() : '');
		return customerID !== prevCustomerID;
	}

	private subscribeToRouteParams() {
		this._routeParamMapSub = this._route.paramMap.subscribe(paramMap => {
			if (this.parseRouteParams(paramMap)) {
				if (this.selectedCustomerID != null) {
					this.refreshRoutes();
				} else {
					this.selectDefaultCustomerID();
				}
			}
		});
	}

	public get selectedCustomerID(): number {
		return InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);
	}

	ngOnDestroy() {
		if (this._routeParamMapSub != null) {
			this._routeParamMapSub.unsubscribe();
		}

		this.cancelRefreshRoutes();
	}

	public onCustomerChanged(customerID: string) {
		this._router.navigate(['/routes', customerID]);
		this.refreshRoutes();
	}

	public refreshRoutes() {
		this.cancelRefreshRoutes();

		this.errorMsg = '';
		this.successMsg = '';

		const customerID = this.selectedCustomerID;

		if (customerID == null) {
			this.routes = [];
			this.loading = false;
			return;
		}

		this.loading = true;
		this._refreshRoutesSub = this._routeService.getCustomerRouteList(customerID).subscribe({
			next: routes => {
				this.routes = routes;
				this.loading = false;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.loading = false;
			}
		});
	}

	private cancelRefreshRoutes() {
		if (this._refreshRoutesSub != null) {
			this._refreshRoutesSub.unsubscribe();
			this._refreshRoutesSub = null;
		}
	}

	public getCustomerID(producer: ICustomer): number {
		return producer.CustomerID;
	}
}
