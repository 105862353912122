import { Component, OnInit, Input } from '@angular/core';
import { InspectionService, IInspection, IError } from 'ee-common-lib';

@Component({
  selector: 'app-next-inspection-date',
  templateUrl: './next-inspection-date.component.html',
  styleUrls: ['./next-inspection-date.component.scss']
})
export class NextInspectionDateComponent implements OnInit {

	@Input() wellID: number;
	nextDate: any = '';
	constructor(private _inspectionService: InspectionService) { }

	ngOnInit() {
		this._inspectionService.getNextInspectionForWell(this.wellID).subscribe({
			next: inspection => {
				if (inspection == null) {
					this.nextDate = 'NA';
				} else {
					this.nextDate = inspection.InspectionDate;
				}
			},
			error: (error: IError) => {
				console.log(error);
			}
		});
	}

}
