import { Component, OnInit, Input } from '@angular/core';
import { InspectionService, IInspection, IError } from 'ee-common-lib';

@Component({
  selector: 'app-recent-inspection-hwl',
  templateUrl: './recent-inspection-hwl.component.html',
  styleUrls: ['./recent-inspection-hwl.component.scss']
})
export class RecentInspectionHwlComponent implements OnInit {

	@Input() wellID: number;
	recentHWL: any = '';
	constructor(private _inspectionService: InspectionService) { }

	ngOnInit() {
		this._inspectionService.getRecentInspectionForWell(this.wellID).subscribe({
			next: inspection => {
				if (inspection == null) {
					this.recentHWL = '';
				} else {
					if (inspection.FireTube.HWL != null) {
						this.recentHWL = inspection.FireTube.HWL;
					} else {
						this.recentHWL = 'NA';
					}
				}
			},
			error: (error: IError) => {
				console.log(error);
			}
		});
	}

}


