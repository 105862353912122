import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig, APP_CONFIG } from 'ee-common-lib';
import { Observable } from 'rxjs';
import { ICustomerWellImportSummary } from './customer-well-import-summary.model';

@Injectable({
	providedIn: 'root'
})
export class ImportService {
	constructor(
		private _http: HttpClient,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {}

	public importCustomerWells(customerWellsCsv: File): Observable<ICustomerWellImportSummary> {
		const requestUrl = `${this._appConfig.apiBaseUrl}/import/customerwells`;

		const requestData = new FormData();
		requestData.append('File', customerWellsCsv);

		return this._http.post<ICustomerWellImportSummary>(requestUrl, requestData);
	}
}
