import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ComponentRef } from '@angular/core';
import { IInspection, IError, InspectionService, IInspectionListItem } from 'ee-common-lib';
import { IScheduleInspectionFormResult, IEditInspectionFormResult } from '../schedule-inspection-form/schedule-inspection-form.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

export interface IScheduleInspectionFailedEvent {
	Inspection: IInspection | IInspectionListItem;
	Error: IError;
}

@Component({
	selector: 'app-schedule-inspection-button',
	templateUrl: './schedule-inspection-button.component.html',
	styleUrls: ['./schedule-inspection-button.component.scss']
})
export class ScheduleInspectionButtonComponent implements OnInit {
	@ViewChild('dropdown', { static: false }) dropdown: NgbDropdown;
	@Input() inspection: IInspection | IInspectionListItem;
	@Input() isFromWell: boolean;
	@Input() wellID: number;
	@Input() location: string;
	@Output() inspectionScheduled: EventEmitter<IInspection>;
	@Output() scheduleInspectionFailed: EventEmitter<IScheduleInspectionFailedEvent>;

	public schedulingInspection: boolean;

	constructor(private _inspectionService: InspectionService) {
		this.inspectionScheduled = new EventEmitter<IInspection>();
		this.scheduleInspectionFailed = new EventEmitter<IScheduleInspectionFailedEvent>();
	}

	ngOnInit() {
	}

	public onInspectionID(inspectionFromWell: IEditInspectionFormResult){
		this.dropdown.close();
		this.schedulingInspection = true;
		const request = this._inspectionService.scheduleExistingInspection(inspectionFromWell.Inspection.InspectionID, inspectionFromWell.Inspection.InspectionDate);
		request.subscribe({
			next: inspection => {
				this.schedulingInspection = false;
				this.inspectionScheduled.emit(inspection);
			},
			error: (error: IError) => {
				this.schedulingInspection = false;
				this.scheduleInspectionFailed.emit({
					Inspection: this.inspection,
					Error: error
				});
			}
		});
	}
	public onSave(result: IScheduleInspectionFormResult): void {
		this.dropdown.close();
		this.schedulingInspection = true;
		const request = this._inspectionService.scheduleExistingInspection(this.inspection.InspectionID, result.InspectionDate);
		request.subscribe({
			next: inspection => {
				this.schedulingInspection = false;
				this.inspectionScheduled.emit(inspection);
			},
			error: (error: IError) => {
				this.schedulingInspection = false;
				this.scheduleInspectionFailed.emit({
					Inspection: this.inspection,
					Error: error
				});
			}
		});
	}

	public onCancel(): void {
		this.dropdown.close();
	}
}
