import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ILookupTableItem, ICustomer, RouteService, IError, IRoute, IUpdateRoute } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EditRouteFormComponent } from '../edit-route-form/edit-route-form.component';

interface IRouteData {
	route: IRoute;
	producers: ICustomer[];
	states: ILookupTableItem[];
}

@Component({
	selector: 'app-update-route',
	templateUrl: './update-route.component.html',
	styleUrls: ['./update-route.component.scss']
})
export class UpdateRouteComponent implements OnInit, OnDestroy {
	@ViewChild(EditRouteFormComponent, { static: false })
		private _routeForm: EditRouteFormComponent;

	private _routeDataSub: Subscription;

	public route: IUpdateRoute;
	public producers: ICustomer[];
	public states: ILookupTableItem[];

	public submitted: boolean;
	public saving: boolean;

	public errorMsg: string;
	public successMsg: string;

	constructor(private _route: ActivatedRoute, private _routeService: RouteService) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.route = this.createEditableRoute(data.route);
				this.producers = data.producers;
				this.states = data.states;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch route data: ${error.Message}`;
			}
		});
	}

	private createEditableRoute(route: IRoute): IUpdateRoute {
		const editableRoute: IUpdateRoute = {
			RouteID: route.RouteID,
			CustomerID: route.CustomerID,
			Name: route.Name,
			Description: route.Description,
			StatesServed: null
		};

		if (route.StatesServed != null) {
			editableRoute.StatesServed = route.StatesServed.map(state => {
				return state.Index;
			});
		}

		return editableRoute;
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public onReset() {
		this.reset();
	}

	public onSubmit() {
		this.submitted = true;
		this.errorMsg = '';
		this.successMsg = '';

		if (this._routeForm.form.invalid) {
			return;
		}

		this.saving = true;
		const formData = this._routeForm.data();
		const updatedRoute = Object.assign({RouteID: this.route.RouteID}, formData);

		this._routeService.updateRoute(updatedRoute).subscribe({
			next: route => {
				this.route = this.createEditableRoute(route);
				this.reset();
				this.saving = false;
				this.successMsg = 'Route was updated successfully';
			},
			error: (error: IError) => {
				this.errorMsg = `Route was not updated: ${error.Message}`;
				this.saving = false;
			}
		});

	}

	public reset() {
		this.submitted = false;
		this._routeForm.reset();
	}

}
