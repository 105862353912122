import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IInvoicedInspectionListItem, InputConverterService, IError, InspectionTableComponent,
	InspectionService, CustomerService, IInspectionListItem, ICustomer, InspectionExportAction,
	ISetInvoicedInspection, IRequestFileAction, IFileInfo, ExportService} from 'ee-common-lib';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, Observable, forkJoin, EMPTY } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

export const DEFAULT_UNBILLED_INSPECTIONS_DAYS_BEFORE = 7;
export const DEFAULT_UNBILLED_INSPECTIONS_REPORT_NAME = 'Unbilled Inspections';

@Component({
	selector: 'app-unbilled-inspections',
	templateUrl: './unbilled-inspections.component.html',
	styleUrls: ['./unbilled-inspections.component.scss']
})
export class UnbilledInspectionsComponent implements OnInit, OnDestroy {
	private _routeParamsSub: Subscription;
	private _routeQueryParamsSub: Subscription;
	private _inspectionsSub: Subscription;

	@ViewChild('inspectionTable', { static: false }) inspectionTable: InspectionTableComponent;

	public loading: boolean;
	public loadingCustomers: boolean;
	public saving: boolean;

	public searchForm: FormGroup;

	public inspections: IInvoicedInspectionListItem[];
	public customers: ICustomer[];

	public billableExportAction = InspectionExportAction.BillableInspections;
	public billableExportFileName = DEFAULT_UNBILLED_INSPECTIONS_REPORT_NAME;

	public errorMsg: string;
	public successMsg: string;

	constructor(
		private _inspectionService: InspectionService,
		private _customerService: CustomerService,
		private _exportService: ExportService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.initSearchForm();
		this.initParams();
		this.fetchInitialData();

		this._routeParamsSub = this._route.paramMap.subscribe(paramMap => {
			if (this.parseRouteParams(paramMap)) {
				this.refreshInspections();
			}
		});

		this._routeQueryParamsSub = this._route.queryParamMap.subscribe(queryParamMap => {
			if (this.parseRouteQueryParams(queryParamMap)) {
				this.refreshInspections();
			}
		});
	}

	public requestInspectionExport: IRequestFileAction = (): Observable<IFileInfo> => {
		let inspectionIDs: number[] = [];
		if (this.inspectionTable != null) {
			inspectionIDs = this.inspectionTable.selectedInspections.map(inspection => {
				return inspection.InspectionID;
			});
		}

		if (inspectionIDs.length === 0) {
			alert('No inspections selected');
			return EMPTY;
		}

		return this._exportService.exportInspections(inspectionIDs, InspectionExportAction.BillableInspections,
			DEFAULT_UNBILLED_INSPECTIONS_REPORT_NAME);
	}

	private initSearchForm() {
		this.searchForm = this._formBuilder.group({
			CustomerID: [''],
			NumDays: ['', [Validators.min(1), Validators.required]],
			ShowAll: [false]
		});
	}

	private initParams() {
		const snapshot = this._route.snapshot;
		this.parseRouteParams(snapshot.paramMap);
		this.parseRouteQueryParams(snapshot.queryParamMap);
	}

	private fetchInitialData() {
		this.loading = true;
		this.loadingCustomers = true;

		const inspectionRequest = this.requestInspections();
		const customersRequest = this.requestCustomers();

		forkJoin([inspectionRequest, customersRequest]).subscribe({
			next: (result) => {
				this.onFetchInspections(result[0]);
				this.onFetchCustomers(result[1]);
			},
			error: this.onError
		});
	}

	private parseRouteParams(paramMap: ParamMap): boolean {
		const numDays = InputConverterService.parseInt(paramMap.get('numDays'));
		const prevNumDays = InputConverterService.parseInt(this.searchForm.controls.NumDays.value);

		const hasChanged = prevNumDays !== numDays;

		this.searchForm.controls.NumDays.setValue(numDays.toString());

		return hasChanged;
	}

	private parseRouteQueryParams(queryParamMap: ParamMap) {
		let showAll = false;
		if (queryParamMap.has('showAll')) {
			showAll = InputConverterService.parseBoolean(queryParamMap.get('showAll'));
		}

		let customerID: number = null;
		if (queryParamMap.has('customerID')) {
			customerID = InputConverterService.parseInt(queryParamMap.get('customerID'));
		}

		const prevCustomerID = InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);
		const prevShowAll: boolean = this.searchForm.controls.ShowAll.value;
		const hasChanged = prevShowAll !== showAll || prevCustomerID !== customerID;

		this.searchForm.controls.ShowAll.setValue(showAll);
		this.searchForm.controls.CustomerID.setValue(customerID != null ? customerID.toString() : '');

		return hasChanged;
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		this._routeQueryParamsSub.unsubscribe();

		this.cancelRefreshInspections();
	}

	public clearErrorMsg(): void {
		this.errorMsg = null;
	}

	public clearSuccessMsg(): void {
		this.successMsg = null;
	}

	private cancelRefreshInspections() {
		if (this._inspectionsSub != null) {
			this._inspectionsSub.unsubscribe();
			this._inspectionsSub = null;
		}
	}

	public refreshInspections(): void {
		this.loading = true;

		this.cancelRefreshInspections();

		const request = this.requestInspections();
		this._inspectionsSub = request.subscribe({
			next: this.onFetchInspections,
			error: this.onError
		});
	}

	private requestInspections(): Observable<IInvoicedInspectionListItem[]> {
		const unbilledOnly: boolean = !this.searchForm.controls.ShowAll.value;
		const numDays = InputConverterService.parseInt(this.searchForm.controls.NumDays.value);
		const customerID = InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);

		return this._inspectionService.getUnbilledInspectionsList(numDays, unbilledOnly, customerID);
	}

	private onFetchInspections = (inspections: IInvoicedInspectionListItem[]) => {
		this.inspections = inspections;
		setTimeout(this.onResetSelected);
		this.loading = false;
	}

	private requestCustomers(): Observable<ICustomer[]> {
		return this._customerService.getAllCustomers();
	}

	private onFetchCustomers = (customers: ICustomer[]) => {
		this.customers = customers;
		this.loadingCustomers = false;
	}

	private onError = (error: IError) => {
		this.errorMsg = error.Message;
		this.loading = false;
		this.loadingCustomers = false;
	}

	public onSave() {
		const selectionState = this.inspectionTable.selectionState;
		if (selectionState.length === 0) {
			alert('No inspections to save');
			return;
		}

		if (!confirm('Save changes?')) {
			return;
		}

		const invoicedInspections = selectionState.map(state => {
			const invoicedInspection: ISetInvoicedInspection = {
				InspectionID: state.item.InspectionID,
				IsInvoiced: state.checked
			};
			return invoicedInspection;
		});

		this.saving = true;
		this._inspectionService.updateInvoicedInspections(invoicedInspections).subscribe({
			next: () => {
				this.successMsg = 'Changes saved successfully';
				this.saving = false;
				this.refreshInspections();
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to save changes: ${error.Message}`;
				this.saving = false;
			}
		});
	}

	public onInspectionExportFailed(event: IError) {
		this.errorMsg = `Failed to export selected inspections: ${event.Message}`;
	}

	public getSelectedInspections(): IInspectionListItem[] {
		if (this.inspectionTable == null) {
			return [];
		}

		return this.inspectionTable.selectedInspections;
	}

	private onResetSelected = () => {
		if (this.inspections == null || this.inspectionTable == null) {
			return;
		}

		this.inspections.forEach(inspection => {
			this.inspectionTable.setSelected(inspection.InspectionID, inspection.IsInvoiced);
		});
	}

	public onCustomerIDChanged(value: string) {
		const customerID = InputConverterService.parseInt(value);

		let queryParams = {};
		if (customerID != null) {
			queryParams = {
				customerID
			};
		}

		this._router.navigate([], {
			relativeTo: this._route,
			queryParams
		});
	}

	public getCustomerID(customer: ICustomer): number {
		return customer.CustomerID;
	}

	public onSearch() {
		if (this.searchForm.invalid) {
			return;
		}

		const customerID = InputConverterService.parseInt(this.searchForm.controls.CustomerID.value);
		const numDays = InputConverterService.parseInt(this.searchForm.controls.NumDays.value);
		const showAll: boolean = this.searchForm.controls.ShowAll.value;

		const queryParams: {customerID?: number, showAll?: boolean} = {};
		if (customerID != null) {
			queryParams.customerID = customerID;
		}

		if (showAll) {
			queryParams.showAll = showAll;
		}

		this._router.navigate(['inspections', 'unbilled', numDays], { queryParams });
		this.refreshInspections();
	}

	public get hasNumDays(): boolean {
		const numDays = InputConverterService.parseInt(this.searchForm.controls.NumDays.value);
		return numDays >= 1;
	}

}
