import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IBaseInspection, InspectionService, InputConverterService, MONTH_YEAR_INPUT_FORMAT } from 'ee-common-lib';
import { IEditInspection, Inspection } from '../../../../ee-common-lib/src/lib/models/edit-inspection.model';
import { IError, IFireTube, ICreateFireTube, IFieldNote, ICreateFieldNote } from '../../../../ee-common-lib/src/public-api';

export interface IScheduleInspectionFormResult {
	InspectionDate: Date;
}

interface IFormData {
	InspectionDate: string;
}
export interface IEditInspectionFormResult {
	Inspection: IEditInspection;
	FieldNoteAssetFile: File;
	InspectionReportFile: File;
}
export interface IScheduleInspectionFromWell {
	InspectionDate: Date;
	InspectionID: number;
}

@Component({
	selector: 'app-schedule-inspection-form',
	templateUrl: './schedule-inspection-form.component.html',
	styleUrls: ['./schedule-inspection-form.component.scss']
})
export class ScheduleInspectionFormComponent implements OnInit, OnChanges {

	public form: FormGroup;
	public minDate: string;
	private _minDate: Date;

	@Input() inspection: IBaseInspection;

	// form submit
	public saved = false;
	@Input() saving: boolean;
	@Input() fromWells: boolean;
	@Input() wellID: number;
	@Output() inspectionID = new EventEmitter<IEditInspectionFormResult>();
	@Input() wellLocation: string;
	@Input() saveButtonLabel: string;
	@Output() cancel = new EventEmitter();
	@Output() save = new EventEmitter<IScheduleInspectionFormResult>();
	result: IEditInspectionFormResult;
	wellInspection: IEditInspectionFormResult;
	constructor(private _formBuilder: FormBuilder, private _inputConverterService: InputConverterService, private _inspectionService: InspectionService) {
		this._minDate = InputConverterService.getDateAsLocal(new Date());
		this.minDate = this._inputConverterService.formatDate(this._minDate, MONTH_YEAR_INPUT_FORMAT);
	}

	ngOnInit() {
		this.form = this._formBuilder.group({
			InspectionDate: ['', Validators.required]
		});

		this.reset();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes) {
			return;
		}

		const inspectionChanges = changes.inspection;
		if (inspectionChanges && inspectionChanges.currentValue !== inspectionChanges.previousValue) {
			this.reset();
		}
	}

	public get controls() {
		return this.form.controls;
	}

	public reset(): void {
		if (this.form == null) {
			return;
		}

		let inspectionDate = this.minDate;
		if (this.inspection != null && this.inspection.InspectionDate >= this._minDate) {
			inspectionDate = this._inputConverterService.formatDate(this.inspection.InspectionDate, MONTH_YEAR_INPUT_FORMAT);
		}

		const formData = {
			InspectionDate: inspectionDate
		};

		this.form.reset(formData);
		this.saved = false;
	}

	public onCancel(): void {
		this.reset();
		this.cancel.emit();
	}

	public onSubmit(): void {
		this.saved = true;

		if (this.form.invalid) {
			return;
		}
		const data = this.getFormResult();
		if (this.fromWells) {
			this._inspectionService.getScheduledInspection(this.wellID).subscribe({
				next: inspCount => {
					if (inspCount > 0) {
						if (confirm('An inspection for this well is already scheduled. Do you want to re-schedule it?')) {
							this.wellInspection = this.AddInspection(data.InspectionDate);
							this._inspectionService.createInspectionFromWells(this.wellInspection.Inspection, this.wellInspection.FieldNoteAssetFile, this.wellInspection.InspectionReportFile).subscribe({
								next: insp => {
									this.wellInspection.Inspection.InspectionID = insp.InspectionID;
									this.wellInspection.Inspection.InspectionDate = insp.InspectionDate;
									this.inspectionID.emit(this.wellInspection);
								},
								error: (error: IError) => {

								}
							});
						}
					}
					else {
						this.wellInspection = this.AddInspection(data.InspectionDate);
						this._inspectionService.createInspectionFromWells(this.wellInspection.Inspection, this.wellInspection.FieldNoteAssetFile, this.wellInspection.InspectionReportFile).subscribe({
							next: insp => {
								this.wellInspection.Inspection.InspectionID = insp.InspectionID;
								this.wellInspection.Inspection.InspectionDate = insp.InspectionDate;
								this.inspectionID.emit(this.wellInspection);
							},
							error: (error: IError) => {

							}
						});
					}
				},
				error: (error: IError) => {

				}
			});
		}
		else {
			
			this.save.emit(data);
		}
	}

	private AddInspection(inspectionDate:Date): IEditInspectionFormResult {
		//const data = this.form.value as IFormData;

		return {
			Inspection: {
				CustomerID: null,
				RouteID: null,
				CustomerRepID: 6,
				InspectionDate: InputConverterService.getDateAsUtc(inspectionDate),
				InspectionID: null,
				ScanTechnicianID: null,
				VesselID: null,
				WellID: this.wellID,
				searchedWell: '',
				ReportID: null,
				ReportName: '',
				Anode: null,
				FieldNote: this.createFieldNote(null),
				FireTube: this.createFireTube(null)
			},
			FieldNoteAssetFile: null,
			InspectionReportFile: null
		};
	}

	private createFireTube(fireTube: IFireTube): ICreateFireTube {
		return {
			HWL: fireTube != null ? fireTube.HWL : null,
			ConditionCategory: fireTube != null ? fireTube.ConditionCategory : null,
			IsWhiteProtocol: fireTube != null ? fireTube.IsWhiteProtocol : false,
			Orientation: fireTube != null ? fireTube.Orientation || '' : 'HORZ',
			//ReplacedGasket: fireTube != null ? fireTube.ReplacedGasket : false,
			//ReplacedGasketIsBillable: fireTube != null ? fireTube.ReplacedGasketIsBillable : false,
			Temperature: fireTube != null ? fireTube.Temperature : null,
			TubeDiameter: '12IN',
			TubeThickness: fireTube != null ? fireTube.TubeThickness : ''
		};
	}

	private createFieldNote(fieldNote: IFieldNote): ICreateFieldNote {
		return {
			Note: fieldNote != null ? fieldNote.Note : null,
			AssetID: fieldNote != null ? fieldNote.AssetID : null
		};
	}

	private getFormResult(): IScheduleInspectionFormResult {
		const data = this.form.value as IFormData;
		return {
			InspectionDate: InputConverterService.getDateAsUtc(data.InspectionDate)
		};
	}
}
