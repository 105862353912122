import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ILookupTableItem, ICustomer, RouteService, IError } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EditRouteFormComponent } from '../edit-route-form/edit-route-form.component';

interface IRouteData {
	producers: ICustomer[];
	states: ILookupTableItem[];
}

@Component({
	selector: 'app-create-route',
	templateUrl: './create-route.component.html',
	styleUrls: ['./create-route.component.scss']
})
export class CreateRouteComponent implements OnInit, OnDestroy {
	@ViewChild(EditRouteFormComponent, { static: false })
		private _routeForm: EditRouteFormComponent;

	private _routeDataSub: Subscription;

	public producers: ICustomer[];
	public states: ILookupTableItem[];

	public submitted: boolean;
	public creating: boolean;

	public errorMsg: string;
	public successMsg: string;

	constructor(private _route: ActivatedRoute, private _routeService: RouteService) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.producers = data.producers;
				this.states = data.states;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch route data: ${error.Message}`;
			}
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public onReset() {
		this.reset();
	}

	public onSubmit() {
		this.submitted = true;
		this.errorMsg = '';
		this.successMsg = '';

		if (this._routeForm.form.invalid) {
			return;
		}

		this.creating = true;
		const route = this._routeForm.data();

		this._routeService.createRoute(route).subscribe({
			next: newRoute => {
				this.reset();
				this.creating = false;
				this.successMsg = 'Route was added successfully';
			},
			error: (error: IError) => {
				this.errorMsg = `Route was not added: ${error.Message}`;
				this.creating = false;
			}
		});

	}

	public reset() {
		this.submitted = false;
		this._routeForm.reset();
	}

}
