import { Component, OnInit, OnDestroy, Inject, Query } from '@angular/core';
import { IInspection, MONTH_YEAR_DATE_FORMAT, ABBREV_MONTH_YEAR_DATE_FORMAT,
	InputConverterService, IError, IKeywordSearchFormResult, InspectionService, ICalendarMonth, IInspectionMonth, IInspectionListItem, ICustomer, CustomerService, IState } from 'ee-common-lib';
import { IScheduleInspectionFailedEvent } from '../schedule-inspection-button/schedule-inspection-button.component';
import { Subscription, Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
interface IRouteData {
	producers: ICustomer[];
}
interface IStateData {
	states: IState[];
}
@Component({
	selector: 'app-scheduled-inspections',
	templateUrl: './scheduled-inspections.component.html',
	styleUrls: ['./scheduled-inspections.component.scss']
})

export class ScheduledInspectionsComponent implements OnInit, OnDestroy {
	private _routeQueryParamsSub: Subscription;
	private _inspectionsSub: Subscription;
	private _routeParamsSub: Subscription;
	private _routeDataSub: Subscription;
	public loading: boolean;
	public keyword: string;
	public remainingInspections: string;
	public inspectionDateFormat: string;
	public currentMonth: ICalendarMonth;
	public inspections: IInspectionListItem[];
	public inspectionsByMonth: IInspectionMonth[];
	public producers: ICustomer[];
	public states: IState[];
	public errorMsg: string;
	public successMsg: string;
	public selectedCustomer: number;

	constructor(
		private _inputConverterService: InputConverterService,
		private _inspectionService: InspectionService,
		private _router: Router,
		private _route: ActivatedRoute, private _customerService: CustomerService) {
			this.inspectionDateFormat = MONTH_YEAR_DATE_FORMAT;
		}

	ngOnInit() {
		this.selectedCustomer = -1;
		this._customerService.getCustomersForFilters().subscribe({
			next: producers => {
				this.producers = producers;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});

		this._customerService.getStates().subscribe({
			next: states => {
				this.states = states;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});

		this._routeParamsSub = this._route.paramMap.subscribe(params => {
			if (params.has('month') && params.has('year')) {
				const year = InputConverterService.parseInt(params.get('year'));
				const month = InputConverterService.parseInt(params.get('month'));

				this.currentMonth = {
					month,
					year
				};
				this.refreshInspections();
			} else {
				this.onCurrentMonthChanged({
					month: InputConverterService.getCurrentMonth(),
					year: InputConverterService.getCurrentYear()
				});

				const snapshot = this._route.snapshot;
				this.parseRouteQueryParams(snapshot.paramMap);
				this.refreshInspections();
			}
		});
	}

	ngOnDestroy() {
		this._routeParamsSub.unsubscribe();
		//this._routeDataSub.unsubscribe();
		this.cancelRefreshInspections();
	}

	private parseRouteQueryParams(queryParamMap: ParamMap) {
		let keyword: string = null;
		if (queryParamMap.has('keyword')) {
			keyword = queryParamMap.get('keyword');
		}

		const hasChanged = this.keyword !== keyword;

		this.keyword = keyword;

		return hasChanged;
	}

	public clearErrorMsg(): void {
		this.errorMsg = null;
	}

	public clearSuccessMsg(): void {
		this.successMsg = null;
	}

	public onInspectionScheduled(inspection: IInspection) {
		this.successMsg = `Inspection scheduled for well ${inspection.WellName} for the month of
			${this._inputConverterService.formatDate(inspection.InspectionDate, ABBREV_MONTH_YEAR_DATE_FORMAT) }`;

		this.refreshInspections();
	}

	public onScheduleInspectionFailed(event: IScheduleInspectionFailedEvent) {
		this.errorMsg = `Failed to schedule inspection for well ${event.Inspection.WellName}: ${event.Error.Message}`;
	}

	public onKeywordChanged(event: IKeywordSearchFormResult) {
		
		let queryParams = null;
		if (InputConverterService.isEmpty(event.SearchTerm)) {
			queryParams = {};
		} else {
			queryParams = {
				keyword: event.SearchTerm,
				customer_ID: event.CustomerID,
				state: event.StateID
			};
		}
		
		this._router.navigate([], {
			relativeTo: this._route,
			queryParams
		});
		this.selectedCustomer = event.CustomerID;
		this.refreshInspections(queryParams.keyword, event.CustomerID, event.StateID);
	}

	public refreshInspections(searchWord: string = null, customer_id: number = -1, state_id: string = "-1") {
		this.cancelRefreshInspections();
		this.loading = true;
		this.inspections = null;
		this._inspectionsSub = this._inspectionService.getScheduledInspectionsListByMonth(
			this.currentMonth.year, this.currentMonth.month, searchWord, this.selectedCustomer, state_id).subscribe({
				next: (inspections: IInspectionListItem[]) => {
					this.inspections = inspections;
					this.loading = false;
					this.remainingInspections = inspections.length.toString();
				},
				error: (error: IError) => {
					this.loading = false;
				}
			});
		
	}

	private cancelRefreshInspections() {
		if (this._inspectionsSub != null) {
			this._inspectionsSub.unsubscribe();
			this._inspectionsSub = null;
		}
	}

	public onPreviousInspectionRequested(previousInspection: IInspection) {
		//this._router.navigate(['inspection', previousInspection.InspectionID, 'details']);
		window.open('inspection/' + previousInspection.InspectionID.toString() + '/details', '_blank');
	}

	public onCurrentMonthChanged(newMonth: ICalendarMonth) {
		this.currentMonth = {
			year: newMonth.year,
			month: newMonth.month
		};

		this._router.navigate(['inspections', 'scheduled', newMonth.year, newMonth.month]);
		this.refreshInspections(null, this.selectedCustomer);
	}

	public onInspectionDeleted(inspection: IInspection) {
		this.successMsg = `Inspection for well ${inspection.WellName} deleted successfully`;
		this.refreshInspections();
	}

	public onInspectionActionFailed(error: IError) {
		this.errorMsg = error.Message;
	}
}
