import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { RouteTreeService, UserService, ICustomerRep, IRouteTree } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-routes-nav',
	templateUrl: './routes-nav.component.html',
	styleUrls: ['./routes-nav.component.scss']
})
export class RoutesNavComponent implements OnInit, OnDestroy {
	public customerRoutes: IRouteTree[];
	public loading: boolean;
	public selectedRouteID: number;
	public routesAcc: NgbAccordion;
	private _routeChangedSubscription: Subscription;
	private _currentUserSubscription: Subscription;
	private _currentRouteSubscription: Subscription;
	private customerId: number;

	constructor(private _routesService: RouteTreeService, private _userService: UserService, private _router: Router) {
	}

	private onSelectedRouteIDChanged = (routeID: number) => {
		this.selectedRouteID = routeID;
		
	}

	private onCustomerRepChanged = (customerRep: ICustomerRep) => {
		//alert(window.location.href);
		//const custID = +window.location.href.split('/')[4];
		//this.customerId = custID;
		this._routesService.setSelectedCustomerID(this.customerId);
		this.fetchCustomerRoutes(this.customerId);
	}

	private onRouteChanged = (event: NavigationEnd) => {
		if (event instanceof NavigationEnd) {
			//alert(window.location.href);
			var url = window.location.href.split('/');
			if (url.length <= 5) {
				const custID = +window.location.href.split('/')[4];
				this.customerId = custID;
				this._routesService.setSelectedCustomerID(this.customerId);
				this.fetchCustomerRoutes(this.customerId);
			}
		}
	}
	
	private fetchCustomerRoutes(customerID: number): void {
		this.customerRoutes = [];
		if (customerID == null || isNaN(customerID)) {
			return;
		}

		this.loading = true;
		this._routesService.getTree(this.customerId).subscribe({
			next: (result: IRouteTree[]) => {
				this.customerRoutes = result;
				this.loading = false;
			},
			error: (err) => {
				this.loading = false;
			}
		});
		//this._routesService.getTreeAll(this.customerId, 244).subscribe({
		//	next: (result: IRouteTree[]) => {
		//		this.customerRoutes = result;
		//		this.loading = false;
		//	},
		//	error: (err) => {
		//		this.loading = false;
		//	}
		//});
	}

	ngOnInit() {
		this._currentUserSubscription = this._userService.currentUser$.subscribe(this.onCustomerRepChanged);
		this._currentRouteSubscription = this._routesService.selectedRouteID$.subscribe(this.onSelectedRouteIDChanged);
		this._routeChangedSubscription = this._router.events.subscribe(this.onRouteChanged);
	}

	ngOnDestroy() {
		this._currentUserSubscription.unsubscribe();
		this._currentRouteSubscription.unsubscribe();
		this._routeChangedSubscription.unsubscribe();
	}
}
