import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ICustomer, ILookupTableItem, CustomerService, IUpdateCustomer, IError, IEditCustomer } from 'ee-common-lib';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EditProducerFormComponent } from '../edit-producer-form/edit-producer-form.component';

interface IRouteData {
	producer: ICustomer;
	states: ILookupTableItem[];
}

@Component({
	selector: 'app-update-producer',
	templateUrl: './update-producer.component.html',
	styleUrls: ['./update-producer.component.scss']
})
export class UpdateProducerComponent implements OnInit, OnDestroy {
	@ViewChild(EditProducerFormComponent, { static: false })
		private _producerForm: EditProducerFormComponent;

	private _routeDataSub: Subscription;

	public producer: IEditCustomer;
	public states: ILookupTableItem[];

	public submitted: boolean;
	public saving: boolean;
	public successMsg: string;
	public errorMsg: string;

	constructor(private _route: ActivatedRoute, private _customerService: CustomerService) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.producer = this.createEditableProducer(data.producer);
				this.states = data.states;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch producer data: ${error.Message}`;
			}
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public onCancel() {
		this.reset();
	}

	public onSubmit() {
		this.submitted = true;
		this.errorMsg = '';
		this.successMsg = '';

		if (this._producerForm.form.invalid) {
			return;
		}

		this.saving = true;
		const editedProducer = this._producerForm.data();

		this._customerService.updateCustomer(editedProducer).subscribe({
			next: result => {
				const updatedProducer = this.createEditableProducer(result);
				this.reset(updatedProducer);

				this.successMsg = 'Producer was updated successfully';
				this.saving = false;
			},
			error: (error: IError) => {
				this.errorMsg = `Producer was not updated: ${error.Message}`;
				this.saving = false;
			}
		});
	}

	public reset(producer?: IEditCustomer) {
		this.submitted = false;

		if (producer !== undefined) {
			this.producer = producer;
		}

		this._producerForm.reset();
	}

	private createEditableProducer(producer: ICustomer): IEditCustomer {
		let statesServed: string[] = [];
		if (producer.StatesServed != null) {
			statesServed = producer.StatesServed.map(state => {
				return state.Index;
			});
		}

		return {
			CustomerID: producer.CustomerID,
			Code: producer.Code,
			Name: producer.Name,
			StatesServed: statesServed
		};
	}

}
