import { Component, OnInit, Input } from '@angular/core';
import { InspectionService, IInspection, IError } from 'ee-common-lib';

@Component({
  selector: 'app-well-name-link',
  templateUrl: './well-name-link.component.html',
  styleUrls: ['./well-name-link.component.scss']
})
export class WellNameLinkComponent implements OnInit {
	@Input() wellName: string;
	@Input() wellID: number;

	isPrevInsp: boolean = false;
	constructor(private _inspectionService: InspectionService) { }

	ngOnInit() {
		this._inspectionService.getRecentInspectionForWell(this.wellID).subscribe({
			next: inspection => {

				if (inspection == null) {
					this.isPrevInsp = false;
				} else {
					this.isPrevInsp = true;
				}
			},
			error: (error: IError) => {
				console.log(error);
			}
		});
	}

}
