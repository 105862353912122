import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UserManagementService } from '../user-management.service';
import { CreateUserFormComponent } from '../create-user-form/create-user-form.component';
import { IError, ICustomer, InputConverterService } from 'ee-common-lib';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ICreateCustomerRep } from '../create-customer-rep.model';

interface IRouteData {
	customers: ICustomer[];
}

@Component({
	selector: 'app-create-customer-rep',
	templateUrl: './create-customer-rep.component.html',
	styleUrls: ['./create-customer-rep.component.scss']
})
export class CreateCustomerRepComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(CreateUserFormComponent, { static: false })
	private _userForm: CreateUserFormComponent;

	private _routeDataSub: Subscription;

	public form: FormGroup;
	public submitted: boolean;
	public creating: boolean;
	public successMsg: string;
	public errorMsg: string;
	public customers: ICustomer[];

	constructor(
		private _userMgmtService: UserManagementService,
		private _formBuilder: FormBuilder,
		private _route: ActivatedRoute) {

	}

	ngOnInit() {
		this.form = this._formBuilder.group({
			CustomerID: ['', Validators.required]
		});

		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.customers = data.customers;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to get list of customers: ${error.Message}`;
			}
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.form.addControl('User', this._userForm.form);
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}

	public get controls() {
		return this.form.controls;
	}

	public getCustomerID(customer: ICustomer): number {
		return customer.CustomerID;
	}

	public onSubmit() {
		this.submitted = true;
		this.successMsg = null;
		this.errorMsg = null;

		if (this.form.invalid) {
			return;
		}

		this.creating = true;
		const newCustomerRep = this.data;

		this._userMgmtService.createCustomerRep(newCustomerRep).subscribe({
			next: user => {
				this.reset();
				this.successMsg = `Customer rep was added successfully`;
				this.creating = false;
			},
			error: (error: IError) => {
				this.errorMsg = `Customer rep was not added: ${error.Message}`;
				this.creating = false;
			}
		});
	}

	public onReset() {
		this.reset();
	}

	public reset() {
		this.submitted = false;
		this.form.reset();
	}

	public get data(): ICreateCustomerRep {
		const customerID = InputConverterService.parseInt(this.controls.CustomerID.value);
		const userData = this._userForm.data;

		const customerRep: ICreateCustomerRep = Object.assign({ CustomerID: customerID }, userData);
		return customerRep;
	}
}
