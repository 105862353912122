import { Component, OnInit, Input } from '@angular/core';
import { InspectionService, IInspection, IError } from 'ee-common-lib';

@Component({
  selector: 'app-recent-inspection-date',
  templateUrl: './recent-inspection-date.component.html',
  styleUrls: ['./recent-inspection-date.component.scss']
})
export class RecentInspectionDateComponent implements OnInit {

	@Input() wellID: number;
	recentDate: any = '';
	constructor(private _inspectionService: InspectionService) { }

	ngOnInit() {
		this._inspectionService.getRecentInspectionForWell(this.wellID).subscribe({
			next: inspection => {
				if (inspection == null) {
					this.recentDate = 'NA';
				} else {
					this.recentDate = inspection.InspectionDate;
				}
			},
			error: (error: IError) => {
				console.log(error);
			}
		});
	}

}

