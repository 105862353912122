import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { InputConverterService, IInspection, MONTH_YEAR_DATE_FORMAT, ABBREV_MONTH_YEAR_DATE_FORMAT, ICustomer, IWellListItem, IPage, IError, WellService, APP_CONFIG, IAppConfig, ICustomerRouteChange } from 'ee-common-lib';
import { IScheduleInspectionFailedEvent } from '../schedule-inspection-button/schedule-inspection-button.component';
interface IRouteData {
	producers: ICustomer[];
}
@Component({
	selector: 'app-well-table',
	templateUrl: './well-table.component.html',
	styleUrls: ['./well-table.component.scss']
})
export class WellTableComponent implements OnInit {
	public errorMsg: string;
	public successMsg: string;
	private _routeDataSub: Subscription;
	private _refreshWellsSub: Subscription;

	private _producerID: number;
	private _routeID: number;

	public producers: ICustomer[];
	@Input() wells: IWellListItem[];

	public loading: boolean;
	public customerID: number;
	public keyword: string;
	public numDays: number;
	public wellPage: IPage<IWellListItem>;
	public page: number;
	public pageSize: number;
	public pageCount: number;
	public totalWellCount: number;
	public inspectionDateFormat: string;
	

	constructor(private _route: ActivatedRoute, private _router: Router, private _inputConverterService: InputConverterService,
		private _wellService: WellService,
		@Inject(APP_CONFIG) private _appConfig: IAppConfig) {
		this.page = 1;
		this.pageSize = this._appConfig.pageSize;
		this.inspectionDateFormat = MONTH_YEAR_DATE_FORMAT;
	}

	ngOnInit() {
		let main = document.querySelector("#main_content");
		main.classList.add("col-lg-12")
		this._routeDataSub = this._route.data.subscribe({
			next: (data: IRouteData) => {
				this.producers = data.producers;
			},
			error: (error: IError) => {
				this.errorMsg = `Failed to fetch well data: ${error.Message}`;
			}
		});

		this.refreshWells();
		
	}

	public getWellID(well: IWellListItem) {
		return well.WellID;
	}

	public onEditWell(wellID: number) {
		this._router.navigate(['well', wellID, 'edit']);
	}

	public onWellActionFailed(error: IError) {
		this.errorMsg = error.Message;
	}

	public onWellDeleted(well: IWellListItem) {
		this.successMsg = `Well ${well.Name} deleted successfully`;
		this.refreshWells();
	}

	private cancelRefreshWells(): void {
		if (this._refreshWellsSub != null) {
			this._refreshWellsSub.unsubscribe();
			this._refreshWellsSub = null;
		}
	}


	public refreshWells(): void {
		this.cancelRefreshWells();

		let request: Observable<IPage<IWellListItem>>;
		if (this._routeID != null) {
			request = this._wellService.getRouteWellList(this._routeID, this.page, this.pageSize, 'nofilter');
		} else if (this._producerID != null) {
			request = this._wellService.getCustomerWellList(this._producerID, this.page, this.pageSize, 'nofilter');
		} else {
			request = this._wellService.getWellList(this.page, this.pageSize, 'nofilter');
		}
		this.wells = null;
		this.loading = true;
		this._refreshWellsSub = request.subscribe({
			next: pageData => {
				this.page = pageData.Page;
				this.pageCount = pageData.PageCount;
				this.pageSize = pageData.PageSize;
				this.totalWellCount = pageData.TotalItemCount;
				this.wells = pageData.Items;

				this.loading = false;
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
				this.loading = false;
			}
		});
	}

	public onInspectionScheduled(inspection: IInspection) {
		this.successMsg = `Inspection scheduled for well ${inspection.WellName} for the month of
		${this._inputConverterService.formatDate(inspection.InspectionDate, ABBREV_MONTH_YEAR_DATE_FORMAT)}`;

		this.refreshWells();
	}

	public onScheduleInspectionFailed(event: IScheduleInspectionFailedEvent) {
		this.errorMsg = `Failed to schedule inspection for well ${event.Inspection.WellName}: ${event.Error.Message}`;
	}

}
