import { Component, OnInit, Input } from '@angular/core';
import { ICustomerListItem, IPage, IError, CustomerService } from 'ee-common-lib';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

interface IRouteData {
	producerList: ICustomerListItem[];
}
@Component({
	selector: 'app-producer-table',
	templateUrl: './producer-table.component.html',
	styleUrls: ['./producer-table.component.scss']
})
export class ProducerTableComponent implements OnInit {
	@Input() producers: ICustomerListItem[];
	public errorMsg: string;
	public successMsg: string;
	private _routeDataSub: Subscription;

	constructor(private _router: Router, private _route: ActivatedRoute, private _customerService: CustomerService) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe((data: IRouteData) => {

			this.producers = data.producerList;
		});

		this._customerService.getCustomerList().subscribe({
			next: producers => {
				this.producers = producers;

				
			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});
	}

	public getProducerID(producer: ICustomerListItem): number {
		return producer.CustomerID;
	}

	public onEditProducer(producerID: number) {
		this._router.navigate(['producer', producerID, 'edit' ]);
	}

	public onCustomerActionFailed(error: IError) {
		this.errorMsg = error.Message;
	}

	public onCustomerDeleted(customer: ICustomerListItem) {
		this.successMsg = `Producer ${customer.Code} deleted successfully`;
		this._customerService.getCustomerList().subscribe({
			next: producers => {
				this.producers = producers;


			},
			error: (error: IError) => {
				this.errorMsg = error.Message;
			}
		});
	}
}
