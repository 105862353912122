import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IInspection } from 'ee-common-lib';
import { Subscription } from 'rxjs';

interface IRouteData {
	inspection: IInspection;
}

@Component({
	selector: 'app-inspection-details',
	templateUrl: './inspection-details.component.html',
	styleUrls: ['./inspection-details.component.scss']
})
export class InspectionDetailsComponent implements OnInit, OnDestroy {
	private _routeDataSub: Subscription;

	public inspection: IInspection;
	constructor(private _route: ActivatedRoute) { }

	ngOnInit() {
		this._routeDataSub = this._route.data.subscribe((data: IRouteData) => {
			this.inspection = data.inspection;
		});
	}

	ngOnDestroy() {
		this._routeDataSub.unsubscribe();
	}
}
