import { Component, OnInit, Input } from '@angular/core';
import { IRoute, RouteService, ActionButton, IError, IRouteListItem } from 'ee-common-lib';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Component({
	selector: 'app-delete-route-button',
	templateUrl: './delete-route-button.component.html',
	styleUrls: ['./delete-route-button.component.scss']
})
export class DeleteRouteButtonComponent extends ActionButton<IRoute | IRouteListItem> implements OnInit {
	@Input() route: IRoute | IRouteListItem;

	constructor(private _routeService: RouteService) {
		super();
	}

	public ngOnInit(): void {
	}

	protected get canExecute(): boolean {
		return this.route !== null;
	}

	protected confirmExecute(): boolean {
		const confirmDelete = confirm('Delete route?');
		return confirmDelete;
	}

	protected execute(): Observable<IRoute | IRouteListItem> {
		return this._routeService.deleteRoute(this.route.RouteID).pipe(map(() => {
			return this.route;
		}), catchError((error: IError) => {
			const errorMsg = `Failed to delete route ${this.route.Name}: ${error.Message}`;
			error = {
				Message: errorMsg
			};

			return throwError(error);
		}));
	}
}
