import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ILookupTableItem, IEditCustomer, InputConverterService } from 'ee-common-lib';

interface IFormData {
	Name: string;
	Code: string;
	StatesServed: string[];
}

@Component({
	selector: 'app-edit-producer-form',
	templateUrl: './edit-producer-form.component.html',
	styleUrls: ['./edit-producer-form.component.scss']
})
export class EditProducerFormComponent implements OnInit, OnChanges {
	@Input() producer: IEditCustomer;
	@Input() showErrors: boolean;
	@Input() states: ILookupTableItem[];

	public form: FormGroup;

	constructor(private _formBuilder: FormBuilder) { }

	ngOnInit() {
		this.form = this._formBuilder.group({
			Name: ['', [Validators.maxLength(100)] ],
			Code: ['', [Validators.required, Validators.maxLength(25)] ],
			StatesServed: [[]]
		});

		this.reset();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes == null || this.form == null) {
			return;
		}

		if (changes.producer != null && changes.producer.currentValue !== changes.producer.previousValue) {
			this.reset();
		}
	}

	public get controls() {
		return this.form.controls;
	}

	public reset() {
		if (this.form == null) {
			return;
		}

		const formData: IFormData = {
			Code: this.producer != null ? this.producer.Code : '',
			Name: this.producer != null ? this.producer.Name : '',
			StatesServed: this.producer != null ? this.producer.StatesServed : []
		};

		this.form.reset(formData);

		if (this.producer != null && this.producer.CustomerID != null) {
			this.controls.Code.disable();
		} else {
			this.controls.Code.enable();
		}
	}

	public data(): IEditCustomer {
		const producer: IEditCustomer = {
			CustomerID: this.producer != null ? this.producer.CustomerID : null,
			Code: InputConverterService.trim(this.form.controls.Code.value),
			Name: InputConverterService.trim(this.form.controls.Name.value),
			StatesServed: this.form.controls.StatesServed.value
		};

		return producer;
	}

	public getIndex(item: ILookupTableItem) {
		return item.Index;
	}
}
