import { Component, OnInit, Input } from '@angular/core';
import { ICustomer, CustomerService, ActionButton, IError, ICustomerListItem } from 'ee-common-lib';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Component({
	selector: 'app-delete-producer-button',
	templateUrl: './delete-producer-button.component.html',
	styleUrls: ['./delete-producer-button.component.scss']
})
export class DeleteProducerButtonComponent extends ActionButton<ICustomer | ICustomerListItem> implements OnInit {
	@Input() customer: ICustomer | ICustomerListItem;

	constructor(private _customerService: CustomerService) {
		super();
	}

	public ngOnInit(): void {
	}

	protected get canExecute(): boolean {
		return this.customer !== null;
	}

	protected confirmExecute(): boolean {
		const confirmDelete = confirm('Delete producer?');
		return confirmDelete;
	}

	protected execute(): Observable<ICustomer | ICustomerListItem> {
		return this._customerService.deleteCustomer(this.customer.CustomerID).pipe(map(() => {
			return this.customer;
		}), catchError((error: IError) => {
			const errorMsg = `Failed to delete producer ${this.customer.Code}: ${error.Message}`;
			error = {
				Message: errorMsg
			};

			return throwError(error);
		}));
	}
}
